import {Component, ElementRef, OnInit, TemplateRef, ViewChild} from '@angular/core';
import { ApiService } from '../../../api/api.service';
import { environment } from '../../../../environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { HelperService } from '../../services/helper.service';
import { Observable } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { FeedbackUserReportInterface } from '../../intefaces/feedback-user-report.interface';
import { ChartConfiguration } from 'chart.js';
import {downloadPdf, truncateString} from '../../helpers';
import { DeviceDetectorService } from 'ngx-device-detector';
import * as moment from 'moment';
import {ConfirmDialogComponent} from '../../dialogs/confirm-dialog/confirm-dialog.component';
import {ToastrService} from 'ngx-toastr';

import {ReportService} from '../../services/report.service';
import {NavLinkInterface} from '../../intefaces/nav-link.interface';

@Component({
    selector: 'app-feedback-user-report',
    templateUrl: './feedback-user-report.component.html',
    styleUrls: ['./feedback-user-report.component.scss']
})
export class FeedbackUserReportComponent implements OnInit {
    public filesPath = environment.filesPath;
    userFullName: string;
    userAvatarUrl: string;
    baseRouter: string;
    userId: number;
    data: FeedbackUserReportInterface;
    feedbackId: number;
    barHorizontalHeight: number;
    isEdit: boolean;
    isVisible: any;
    description: string;
    devTipsIsVisible: boolean;
    item: object;
    positions: any[] = [];
    activeTab = 'user-report';
    isConfirmed: boolean = false;
    public navLinks: NavLinkInterface[] = [];
    showNav: boolean = false;

    public radarChartOptions: ChartConfiguration['options'] = {
        maintainAspectRatio: false,
        scales: {
            yAxes: [{
                display: false
            }],
            xAxes: [{
                display: false
            }]
        },
        scale: {
            ticks: {
                beginAtZero: true,
                min: 0,
                stepSize: 1
            }
        },
        tooltips: {
            callbacks: {
                label: function (tooltipItem, data) {
                    return data.datasets[tooltipItem.datasetIndex].label + ': ' + Number(tooltipItem.yLabel).toFixed(2);
                },
                title: function(tooltipItem, data) {
                    return '';
                }
            }
        }
    };

    showInfo: boolean = false;
    view: any[] = [86, 77];

    lowestRatedValue: any[] = [];
    topRatedValue: any[] = [];
    lowestRatedColor = {
        domain: ['#DC555F']
    };

    topRatedColor = {
        domain: ['#5AA454']
    };

    domainColors = {
        self: '#70BEBF',
        supervisor: '#FB9678',
        team: '#F6CE6B',
        employee: '#43B3DD',
        coworker: '#EA6D85',
        others: '#F0A354',
        avg: '#F0A354',
    }
    avgDotClass: string;

    xAxisTicks: number[] = [];
    isMobile: boolean = false;
    constructor(private _api: ApiService,
                private _router: Router,
                private _dialog: MatDialog,
                private _translate: TranslateService,
                private activatedRoute: ActivatedRoute,
                public _helper: HelperService,
                private deviceService: DeviceDetectorService,
                private _toastrService: ToastrService,
                private reportService: ReportService) {
        this._helper.createTitle(['Common.Feedback', 'Feedback.CollectFeedback'])
        this.userFullName = `${this._helper.getUser().name} ${this._helper.getUser().surname}`;
        this.userAvatarUrl = this._helper.getUser().avatarUrl;
        this.isMobile = !this.deviceService.isDesktop();
        this.isEdit = false;
    }

    ngOnInit(): void {

        this.activatedRoute.paramMap.pipe(
            tap((params) => {
                this.feedbackId = Number(params.get('feedbackId'));
                this.userId = Number(params.get('userId')) ? Number(params.get('userId')) : this._helper.getUser().id;
            }),
            switchMap(() => this.getEndpoint())
        ).subscribe((response: FeedbackUserReportInterface) => {
            this.data = response;

            this.baseRouter = this.setBaseRouter();
            this.prepareData(response);
            this.lowestRatedValue = [
                {
                    name: '',
                    value: this.data.competences.min.avg
                }
            ];
            this.topRatedValue = [
                {
                    name: '',
                    value: this.data.competences.max.avg
                }
            ];
        });
    }

    setBaseRouter(): string {
        if (this._router.url.includes('organization')) {
            return `/app/organization/feedbacks/report/${this.data.questionnaire.id}/results`
        } else if (this._router.url.includes('team')) {
            return `/app/team/feedbacks/report/${this.data.questionnaire.id}/results`
        } else {
            return '/app/feedback'
        }
    }

    prepareData(response: FeedbackUserReportInterface): FeedbackUserReportInterface {
        response.avg.competences.labels = response.avg.competences.labels.map(label => truncateString(label, this.isMobile ? 20 : 50));
        response.competences.maxValue = response.competences.maxValue ? response.competences.maxValue : 1;
        response.avg.competences.datasets[0] = {
            ...response.avg.competences.datasets[0],
            label: this._translate.instant('FeedbackUserReport.AverageRating'),
            backgroundColor: ['rgba(255, 99, 132, 0.4)'],
            borderColor: ['rgba(255, 99, 132, 1)'],
            borderWidth: 1
        };
        response.avg.competences.datasets[1] = {
            ...response.avg.competences.datasets[1],
            label: this._translate.instant('Feedback.SelfEsteem'),
            backgroundColor: ['rgba(54, 162, 235, 0.4)'],
            borderColor: ['rgba(54, 162, 235, 1)'],
            borderWidth: 1
        };
        response.avg.competences.datasets[2] = {
            ...response.avg.competences.datasets[2],
            label: this._translate.instant('FeedbackStats.SupervisorAssessment'),
            backgroundColor: ['rgba(255, 159, 64, 0.4)'],
            borderColor: ['rgba(255, 159, 64, 1)'],
            borderWidth: 1
        };

        response.competences.min = {
            id: response.competences.min ? response.competences.min.id : null,
            description: response.competences.min ? response.competences.min.description : '',
            avg: response.competences.min ? Number(Number(response.competences.min.avg).toFixed(2)) : 0
        }
        response.competences.max = {
            id: response.competences.max ? response.competences.max.id : null,
            description: response.competences.max ? response.competences.max.description : '',
            avg: response.competences.max ? Number(Number(response.competences.max.avg).toFixed(2)) : 0
        }


        this.xAxisTicks = this.prepareXscale([...Array(response.competences.maxValue + 1).keys()], response.competences.maxValue);


        if (response.avg.others) {
            if (!response.avg.supervisor) {
                this.domainColors.others = this.domainColors.supervisor;
                this.avgDotClass = 'average-rating__dot--supervisor';
            } else if (!response.avg.team) {
                this.domainColors.others = this.domainColors.team;
                this.avgDotClass = 'average-rating__dot--team';
            } else if (!response.avg.employee) {
                this.domainColors.others = this.domainColors.employee;
                this.avgDotClass = 'average-rating__dot--employee';
            } else if (!response.avg.coworker) {
                this.domainColors.others = this.domainColors.coworker;
                this.avgDotClass = 'average-rating__dot--coworker';
            }
        }

        this.prepareAttendanceTable(response);
        this.prepareCompetencesInAreas(response);
        this.prepareAreas(response);

        this.barHorizontalHeight = !this.data.reportHideNumbers ? 30 : 15;
        this.isConfirmed = response.isConfirmed;

        this.devTipsIsVisible = this._helper.hasPermissions(['organization:manage','team:manage']) ||
                    this._helper.hasPermissions(['user:feedback:dashboard']) && response.devTips.userIndividualDevTipIsVisible ? true : false;
        if(response.devTips.userIndividualDevTipIsVisible && response.devTips.userIndividualDevTip !== '' && response.userShareResultsAfterCampaign){
            this.showNav = true;
        }
        this.setNavigation();
        return response
    }

    prepareXscale(scale, maxValue) {

        let ceil = Math.ceil((maxValue/25));
        if(ceil === 0){
            ceil = 1;
        }
        const items = [];
        for (let i = 0; i <= maxValue; i += ceil) {
            items.push(i);
        }
        return items;
    }

    prepareAttendanceTable(response: FeedbackUserReportInterface) {
        response.attendanceTable = [];
        for (const [key, value] of Object.entries(response.attendance)) {
            if (Number(value.invited) > 0 || Number(value.finished) > 0 || Number(value.rejected) > 0)
                response.attendanceTable.push({
                    name: key,
                    title: this.getTranslateAttendance(key),
                    invited: value.invited,
                    finished: value.finished,
                    rejected: value.rejected
                })
        }
    }

    prepareCompetencesInAreas(response: FeedbackUserReportInterface) {
        response.competencesInAreas.forEach(comp => {
            comp.chartData = [];
            comp.chartColors = [];

            for (const [key, value] of Object.entries(this.domainColors)) {
                if (comp[key] != null) {
                    comp.chartData.push({
                        name: key,
                        value: comp[key]
                    })
                    comp.chartColors.push(value)
                }
            }

        })
    }

    prepareAreas(response: FeedbackUserReportInterface) {
        let index = 1;
        response.areas.forEach(area => {
            area.competences.forEach(comp => {
                comp.chartData = [];
                comp.chartColors = [];
                comp.index = index;

                for (const [key, value] of Object.entries(this.domainColors)) {
                    if (comp.avg && comp.avg[key] != null && key !== 'avg') {
                        comp.chartData.push({
                            name: key,
                            value: comp.avg[key]
                        })
                        comp.chartColors.push(value)
                    }
                }

                index = ++index;
                comp.behaviours.forEach(behaviour => {
                    var element = [];
                    behaviour.chartData = [];
                    behaviour.chartColors = [];
                    behaviour.distributionArray = [];
                    behaviour.emptyResponsesArray = [];
                    behaviour.noValueAnswersArray = [];
                    const behaviorFromZero = behaviour.answers.find(val => Number(val.value) === 0)
                    behaviour.answers = behaviour.answers; //.sort((a, b) => Number(a.value) - Number(b.value));
                    behaviour.fromZero = behaviour.fromZero || behaviorFromZero;
                    if (behaviour.fromZero) {
                        behaviour.maxValue = behaviour.maxValue + 1;
                    }
                    behaviour.answers.forEach(item =>{
                       if(item.answer.length > 80){
                           element.push(2);
                       }else{
                           element.push(1);
                       }
                    });
                    behaviour.xAxisTicks = this.prepareXscale([...Array(behaviour.maxValue + 1).keys()], behaviour.maxValue);
                    for (const [key, value] of Object.entries(this.domainColors)) {
                        if (behaviour.avg && behaviour.avg[key] != null && key !== 'avg') {

                            behaviour.chartData.push({
                                name: key,
                                value: behaviour.fromZero ? behaviour.avg[key] + 1 : behaviour.avg[key]
                            })

                            behaviour.chartColors.push(value)
                        }

                        if (behaviour.questionType !== 'open' && key !== 'avg' && behaviour.distribution && behaviour.distribution[key]) {
                            if (typeof behaviour.distribution[key] === 'object' && behaviour.distribution[key] !== null) {
                                const array = [];
                                for (const [distributionName, distributionValue] of Object.entries(behaviour.distribution[key])) {
                                    array.push(distributionValue)
                                }
                                behaviour.distribution[key] = [...array];
                            }

                            if(behaviour.noValueAnswers !== null && behaviour.noValueAnswers[key] > 0){
                                behaviour.distributionArray.push({
                                    name: key,
                                    avg: behaviour.distribution[key].reduce((accumulator, currentValue) => accumulator + currentValue) + behaviour.noValueAnswers[key],
                                    valueArray: [...behaviour.distribution[key]],
                                    items:element

                                })
                            }else{
                                behaviour.distributionArray.push({
                                    name: key,
                                    avg: behaviour.distribution[key].reduce((accumulator, currentValue) => accumulator + currentValue),
                                    valueArray: [...behaviour.distribution[key]],
                                    items:element
                                })
                            }

                            if (behaviour.emptyResponses) {
                                behaviour.emptyResponsesArray.push(behaviour.emptyResponses[key])
                            }
                            if (behaviour.noValueAnswers) {
                                behaviour.noValueAnswersArray.push(behaviour.noValueAnswers[key])
                            }
                        }
                    }
                })
            })
        })

    }

    xAxisTickFormattingFromZero = (value) => {
        if (!this.data.reportHideNumbers) {
            return value !== 0 ? value - 1 : '';
        } else {
            return '';
        }
    }

    xAxisTickFormattingHiddeNumber = (value) => {
        return !this.data.reportHideNumbers ? value : '';
    }

    public getTranslateAttendance(type: string): string {
        switch (type) {
            case 'self':
                return 'Feedback.SelfEsteem';
            case 'supervisor':
                return 'Common.Supervisor';
            case 'team':
                return 'Common.Team';
            case 'employee':
                return 'Common.Workers';
            case 'coworker':
                return 'Common.Associates';
            case 'avg':
                return 'Common.Feedback';
            case 'others':
                return 'Common.FeedbackOthers';
            default:
                return '';
        }
    }

    getAvg(width: number, items: number, avg: number, isLabel: boolean = false, max: any = 0){
        if(isLabel){
            return ((width - 40) / (items - 1)) * avg;
        }
        return 14 + (((width - 40) / (items - 1)) * avg);
    }

    countElements(elements: any){
        return elements.length;
    }

    getEndpoint(): Observable<any> {
        if (this._router.url.includes('organization')) {
            return this._api.Feedback.getUserReportOrganization(this._helper.getUser().companyId, this.feedbackId, this.userId)
        } else if (this._router.url.includes('team')) {
            return this._api.Feedback.getUserReportTeam(this._helper.getUser().id, this.feedbackId, this.userId)
        } else {
            return this._api.Feedback.getUserReport(this.feedbackId)
        }
    }

    getPercent(value: number): string {
        return ((value * 100) / this.data.competences.maxValue).toFixed(0);
    }

    private cutLabel(label: string): string {
        return label.length > 50 ? label.substring(0, 50) + '...' : label;
    }

    downloadReport(): void {
        let ep: Observable<any>;

        const svgGaugeLowest = null; // document.getElementsByClassName('chart-min')[0]?.toDataURL('2d');;
        const svgGaugeTop = null; // document.getElementsByClassName('chart-max')[0]?.toDataURL('2d');;
        // @ts-ignore
        const base64Radar = document.getElementsByClassName('radar-chart')[0]?.toDataURL('2d');

        const images = {
            svgGaugeLowest,
            svgGaugeTop,
            base64Radar
        };

        const translations = {
            'FeedbackUserReport.Duration': this._translate.instant('FeedbackUserReport.Duration'),
            'FeedbackUserReport.EvaluatedEmployee': this._translate.instant('FeedbackUserReport.EvaluatedEmployee'),
            'Common.Email': this._translate.instant('Common.Email'),
            'Common.Position': this._translate.instant('Common.Position'),
            'Common.Supervisor': this._translate.instant('Common.Supervisor'),
            'FeedbackUserReport.Summary': this._translate.instant('FeedbackUserReport.Summary'),
            'QuestionnaireCreator.Competences': this._translate.instant('QuestionnaireCreator.Competences'),
            'FeedbackStats.TopRatedcompetences': this._translate.instant('FeedbackStats.TopRatedcompetences'),
            'FeedbackStats.LowestRatedcompetences': this._translate.instant('FeedbackStats.LowestRatedcompetences'),
            'Feedback.Scale': this._translate.instant('Feedback.Scale'),
            'FeedbackUserReport.MyCompetenceAverageRating': this._translate.instant('FeedbackUserReport.MyCompetenceAverageRating'),
            'FeedbackUserReport.AverageRating': this._translate.instant('FeedbackUserReport.AverageRating'),
            'FeedbackUserReport.Contents': this._translate.instant('FeedbackUserReport.Contents'),
            'Common.Introduction': this._translate.instant('Common.Introduction'),
            'FeedbackUserReport.Frequency': this._translate.instant('FeedbackUserReport.Frequency'),
            'FeedbackUserReport.AssessmentOfCompetenciesInTheAreas': this._translate.instant('FeedbackUserReport.AssessmentOfCompetenciesInTheAreas'),
            'FeedbackUserReport.DetailedAssessmentOfCompetencies': this._translate.instant('FeedbackUserReport.DetailedAssessmentOfCompetencies'),
            'FeedbackUserReport.BehaviorDetails': this._translate.instant('FeedbackUserReport.BehaviorDetails'),
            'Feedback.Invited': this._translate.instant('Feedback.Invited'),
            'FeedbackUserReport.HasGivenAFeedback': this._translate.instant('FeedbackUserReport.HasGivenAFeedback'),
            'FeedbackUserReport.Rejected': this._translate.instant('FeedbackUserReport.Rejected'),
            'QuestionnaireCreator.Area': this._translate.instant('QuestionnaireCreator.Area'),
            'QuestionnaireCreator.Competence': this._translate.instant('QuestionnaireCreator.Competence'),
            'FeedbackUserReport.ResponseDistribution': this._translate.instant('FeedbackUserReport.ResponseDistribution'),
            'FeedbackUserReport.OpenAnswers': this._translate.instant('FeedbackUserReport.OpenAnswers'),
            'Common.NoAnswer': this._translate.instant('Common.NoAnswer'),
            'Common.NoValueAnswers': this._translate.instant('Common.NoValueAnswers'),
            'Feedback.SelfEsteem': this._translate.instant('Feedback.SelfEsteem'),
            'Common.Team': this._translate.instant('Common.Team'),
            'Common.Workers': this._translate.instant('Common.Workers'),
            'Common.Associates': this._translate.instant('Common.Associates'),
            'Common.FeedbackOthers': this._translate.instant('Common.FeedbackOthers'),
            'FeedbackReport.NotEnoughDataToDisplayTheGraph': this._translate.instant('FeedbackReport.NotEnoughDataToDisplayTheGraph'),
            'FeedbackUserReport.DevelopmentTips': this._translate.instant('FeedbackUserReport.DevelopmentTips'),
            'FeedbackReport.CanNotDisplayTheGraph': this._translate.instant('FeedbackReport.CanNotDisplayTheGraph'),
            'FeedbackReport.CompetenceOutOfAverage': this._translate.instant('FeedbackReport.CompetenceOutOfAverage'),
            'FeedbackReport.CompetenceContainsOnlyOpenQuestions': this._translate.instant('FeedbackReport.CompetenceContainsOnlyOpenQuestions'),
            'QuestionnaireCreator.IndividualDevelopmentTips': this._translate.instant('QuestionnaireCreator.IndividualDevelopmentTips'),
            'FeedbackUserReport.OrganizationAvg': this._translate.instant('FeedbackUserReport.OrganizationAvg'),
        };

        const dateFile = moment().format('YYYYMMDD_HHmm')
        const fileName = `${this.data.user.firstName}_${this.data.user.surname}_feedback_${dateFile}`;
        const body = {
            images,
            translations
        };

        if (this._router.url.includes('organization')) {
            const url = this._api.Feedback.getUserReportOrganizationPDF(this._helper.getUser().companyId, this.feedbackId, this.userId);
            this.reportService.create('feedback', url, 'post', fileName, 'pdf', body);
            // ep = this._api.Feedback.getUserReportOrganizationPDF(this._helper.getUser().companyId, this.feedbackId, this.userId, images, translations);
        } else if (this._router.url.includes('team')) {
            const url = this._api.Feedback.getUserReportTeamPDF(this._helper.getUser().id, this.feedbackId, this.userId);
            this.reportService.create('feedback', url, 'post', fileName, 'pdf', body);
            // ep = this._api.Feedback.getUserReportTeamPDF(this._helper.getUser().id, this.feedbackId, this.userId, images, translations);
        } else {
            const url = this._api.Feedback.getUserReportPDF(this.feedbackId);
            this.reportService.create('feedback', url, 'post', fileName, 'pdf', body);
        }
    }

    changeShare(event: any): void {
        this._api.Feedback.shareReportForSupervisor(this.feedbackId, { value: event.checked }).subscribe();
    }

    setGaugeDate(percentGauge: any, colorGauge: any, left: number): any {
        return {
            percent: percentGauge,
            color: colorGauge,
            left
        }
    }

    confirmAssessment(){
        this._dialog.open(ConfirmDialogComponent, {
            width: '608px',
            disableClose: true,
            data: {
                title: 'FeedbackUserReport.Confirmation',
                content: 'FeedbackUserReport.AssessmentResultsConfirmedInfo',
                okText: 'Common.IConfirm',
                cancelText: 'Common.Cancel',
            }
        }).afterClosed().subscribe(
            (data) => {
                if (data) {
                    this._api.Feedback.confirmAssessment(
                        this._helper.getUser().companyId,
                        this.feedbackId,
                        this.userId,
                        this.data.questionnaire.id
                    ).subscribe((res) => {
                        this._toastrService.success(this._translate.instant('FeedbackUserReport.AssessmentConfirmed'));
                        this.isConfirmed = true;
                        this.data.confirmedDate = res.confirmedDate;
                    });
                }
            }
        );
    }

    setNavigation() {
        if(this._helper.hasPermissions(['organization:manage','team:manage']) || this._helper.isSysAdmin()) {
            this.navLinks = [
                {
                    path: '/app/organization/feedbacks/' + this.feedbackId + '/user-report/' + this.userId,
                    isActive: document.location.href.indexOf('user-report') > 0,
                    isVisible: true,
                    label: 'FeedbackUserReport.AssessmentResults'
                },
                {
                    path: '/app/organization/feedbacks/' + this.feedbackId + '/development-tips/' + this.userId,
                    isActive: document.location.href.indexOf('development-tips') > 0,
                    isVisible: true,
                    label: 'QuestionnaireCreator.PostAssessmentSummary'
                },
            ]
        }else {
            if (this.showNav) {
                this.navLinks = [
                    {
                        path: '/app/feedback/' + this.feedbackId + '/user-report',
                        isActive: document.location.href.indexOf('user-report') > 0,
                        isVisible: true,
                        label: 'FeedbackUserReport.AssessmentResults'
                    },
                    {
                        path: '/app/feedback/' + this.feedbackId + '/development-tips',
                        isActive: document.location.href.indexOf('development-tips') > 0,
                        isVisible: true,
                        label: 'QuestionnaireCreator.PostAssessmentSummary'
                    },
                ]
            }
        }
    }

}
