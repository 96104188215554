import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
    FeedbackCampaignList, FeedbackQuestionnaireList,
    FeedbackResponseTemplatesList
} from '../../feedback-creator/interfaces/feedback-list.interfaces';
import {
    QuestionnaireInventionsList,
    QuestionnaireParticipantsList, QuestionnaireParticipantsSummary
} from '../../feedback-creator/interfaces/feedback-assign.interface';
import { CollectFeedback } from '../../feedback/interfaces/collect-feedback.interface';
import { GiveFeedbackArrays } from '../../feedback/interfaces/give-feedback.interface';
import { FinishedFeedback } from '../../feedback/interfaces/finished-feedback.interface';
import { OrgFeedbacksResultList } from '../../organization/interfaces/feedback-reports.interface';
import { TeamFeedbacksResultList } from '../../team/interfaces/feedback-reports.interface';
import { FeedbackUserReportInterface } from '../../shared/intefaces/feedback-user-report.interface';
import { FeedbackStatsReportInterface } from '../../shared/intefaces/feedback-stats-report.interface';

@Injectable({
    providedIn: 'root'
})
export class FeedbackService {
    protected basePath = environment.apiPath;
    public defaultHeaders = new HttpHeaders();
    private bodyBlob = {
        observe: 'response',
        responseType: 'blob',
        headers: 'X-Downloadable:true'
    }

    constructor(protected http: HttpClient) {
        this.defaultHeaders = this.defaultHeaders.append('Accept', 'application/json');
        this.defaultHeaders = this.defaultHeaders.append('Content-Type', 'application/json');
    }

    public selfFeedback(pageNumber: number, pageSize: number, sortBy: string, order: string, params: string): Observable<FeedbackCampaignList> {
        const headers = this.defaultHeaders;
        return this.http.get<FeedbackCampaignList>(`${this.basePath}/admin/feedback?pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&${params}`, { headers });
    }

    public selfFeedbackXLS(params: string): string {
        return `/admin/feedback/xls?${params}`;
    }

    public addEditCampaign(body: any): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.post<any>(`${this.basePath}/admin/feedback`, body, { headers });
    }

    public removeCampaign(id: number): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.delete<any>(`${this.basePath}/admin/feedback/${id}`, { headers });
    }

    public getCampaign(id: number): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/admin/feedback/${id}`, { headers });
    }

    public cloneCampaign(body: any): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.post<any>(`${this.basePath}/admin/feedback/clone`, body, { headers });
    }

    public getHasActiveCampaign(): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/admin/feedback/has-active`, { headers });
    }

    public checkCampaignName(body: any): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.post<any>(`${this.basePath}/feedback/check-name`, body, { headers });
    }

    public selfAnswerTemplate(pageNumber: number, pageSize: number, sortBy: string, order: string, params: string): Observable<FeedbackResponseTemplatesList> {
        const headers = this.defaultHeaders;
        return this.http.get<FeedbackResponseTemplatesList>(`${this.basePath}/admin/feedback-answer-template?pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&${params}`, { headers });
    }

    public getTemplates(): Observable<FeedbackResponseTemplatesList> {
        const headers = this.defaultHeaders;
        return this.http.get<FeedbackResponseTemplatesList>(`${this.basePath}/admin/feedback-answer-template?pageNumber=1&pageSize=9999&sortBy=name&order=asc`, { headers });
    }

    public getTemplatesWithSelectedItem(id: number): Observable<FeedbackResponseTemplatesList> {
        const headers = this.defaultHeaders;
        return this.http.get<FeedbackResponseTemplatesList>(`${this.basePath}/admin/feedback-answer-template-with-selected/${id}?pageNumber=1&pageSize=9999&sortBy=name&order=asc`, { headers });
    }

    public addEditAnswerTemplate(body: any): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.post<any>(`${this.basePath}/admin/feedback-answer-template`, body, { headers });
    }

    public removeAnswerTemplate(id: number): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.delete<any>(`${this.basePath}/admin/feedback-answer-template/${id}`, { headers });
    }

    public getAnswerTemplate(id: number): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/admin/feedback-answer-template/${id}`, { headers });
    }

    public checkAnswerTemplate(body: any): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.post<any>(`${this.basePath}/feedback-answer-template/check-name`, body, { headers });
    }

    public selfQuestionnaire(feedbackId: number, pageNumber: number, pageSize: number, sortBy: string, order: string, params: string): Observable<FeedbackQuestionnaireList> {
        const headers = this.defaultHeaders;
        return this.http.get<FeedbackQuestionnaireList>(`${this.basePath}/admin/feedback/${feedbackId}/questionnaire?pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&${params}`, { headers });
    }

    public selfQuestionnaireXLS(feedbackId: number, params: string): string {
        return `/admin/feedback/${feedbackId}/questionnaire/xls?${params}`;
    }

    public globalQuestionnaire(pageNumber: any, pageSize: any, sortBy: any, order: any, params: string): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/admin/feedback/questionnaire?pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&${params}`, { headers });
    }

    public globalQuestionnaireWithParams(params: string): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/admin/feedback/questionnaire?pageNumber=1&pageSize=50&sortBy=id&order=asc&status[]=active&status[]=inactive${params ? params : ''}`, { headers });
    }

    public globalQuestionnaireXLS(params: string, body: any): Observable<any> {
        // @ts-ignore
        // @ts-disable
        return this.http.post<HttpResponse<Blob>>(`${this.basePath}/admin/feedback/questionnaire/xls?${params}`, body, { ...this.bodyBlob });
    }

    public getCreatorQuestionnaire(params?: string): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/admin/feedback/0/questionnaire?pageNumber=1&pageSize=50&sortBy=id&order=asc&status[]=active&status[]=inactive${params ? params : ''}`, { headers });
    }

    public addQuestionnaire(id: number, body: any): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.post<any>(`${this.basePath}/admin/feedback/${id}/questionnaire`, body, { headers });
    }

    public addGlobalQuestionnaire(body: any): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.post<any>(`${this.basePath}/admin/feedback/questionnaire`, body, { headers });
    }

    public getQuestionnaire(id: number, params: string = ''): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/admin/feedback/0/questionnaire/${id}${params}`, { headers });
    }

    public checkQuestionnaireName(body: any): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.post<any>(`${this.basePath}/feedback/questionnaire/check-name`, body, { headers });
    }

    public getQuestionnaireName(feedbackId: number, id: number): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/feedback/${feedbackId}/questionnaire/${id}/name`, { headers });
    }

    public userChecklist(id: number, param: string = ''): Observable<any> {
        const headers = this.defaultHeaders;
        const type = param ? `?type=${param}` : '';
        return this.http.get<any>(`${this.basePath}/admin/feedback-questionnaire/${id}/user-checklist${type}`, { headers });
    }

    public userDashboardChecklist(id: number, param: string = ''): Observable<any> {
        const headers = this.defaultHeaders;
        const type = param ? `?type=${param}` : '';
        return this.http.get<any>(`${this.basePath}/user/feedback/${id}/user-checklist${type}`, { headers });
    }

    public assign(id: number, body: any): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.post<any>(`${this.basePath}/admin/feedback-questionnaire/${id}/assign`, body, { headers });
    }

    public assignedQuestionnaire(questionnaireId: number, pageNumber: number, pageSize: number, sortBy: string, order: string, params: string): Observable<QuestionnaireParticipantsList> {
        const headers = this.defaultHeaders;
        return this.http.get<QuestionnaireParticipantsList>(`${this.basePath}/admin/feedback-questionnaire/${questionnaireId}/assigned-questionnaire?pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&${params}`, { headers });
    }

    public assignedQuestionnaireXLS(questionnaireId: number, params: string): string {
        return `/admin/feedback-questionnaire/${questionnaireId}/assigned-questionnaire/xls?${params}`;
    }

    public subordinatesAssignedQuestionnaire(pageNumber: number, pageSize: number, sortBy: string, order: string, params: string): Observable<QuestionnaireParticipantsList> {
        const headers = this.defaultHeaders;
        return this.http.get<QuestionnaireParticipantsList>(`${this.basePath}/user/subordinate-assigned-questionnaire?pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&${params}`, { headers });
    }

    public subordinatesAssignedQuestionnaireSummary(): Observable<QuestionnaireParticipantsSummary> {
        const headers = this.defaultHeaders;
        return this.http.get<QuestionnaireParticipantsSummary>(`${this.basePath}/user/subordinate-assigned-questionnaire-summary`, { headers });
    }

    public subordinatesAssignedQuestionnaireXLS( params: string, body: any): Observable<any> {
        // @ts-ignore
        // @ts-disable
        return this.http.post<HttpResponse<Blob>>(`${this.basePath}/user/subordinate-assigned-questionnaire/xls?${params}`, body, { ...this.bodyBlob });
    }

    public assignedList(questionnaireId: number): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/admin/feedback-questionnaire/${questionnaireId}/assigned`, { headers });
    }

    public assignedCount(id: number): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/admin/feedback-questionnaire/${id}/assigned/count`, { headers });
    }

    removeSelfAssessment(questionnaireId: number, body: any): Observable<any> {
        const options = {
            headers: this.defaultHeaders,
            body
        };
        return this.http.delete<any>(`${this.basePath}/admin/feedback-questionnaire/${questionnaireId}/self-assessment`, options);
    }

    public inviteSupervisor(questionnaireId: number, body: any): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.post<any>(`${this.basePath}/admin/feedback-questionnaire/${questionnaireId}/invite-supervisor`, body, { headers });
    }

    public inviteUser(questionnaireId: number, body: any): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.post<any>(`${this.basePath}/admin/feedback-questionnaire/${questionnaireId}/invite-user`, body, { headers });
    }

    public acceptUserInvitation(questionnaireId: number, body: any): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.post<any>(`${this.basePath}/admin/feedback-questionnaire/${questionnaireId}/invite-user-accept`, body, { headers });
    }

    removeInvitation(questionnaireId: number, body: any): Observable<any> {
        const options = {
            headers: this.defaultHeaders,
            body
        };
        return this.http.delete<any>(`${this.basePath}/admin/feedback-questionnaire/${questionnaireId}/invitation`, options);
    }

    public invitations(questionnaireId: number, pageNumber: number, pageSize: number, sortBy: string, order: string, params: string): Observable<QuestionnaireInventionsList> {
        const headers = this.defaultHeaders;
        return this.http.get<QuestionnaireInventionsList>(`${this.basePath}/admin/feedback-questionnaire/${questionnaireId}/invitations?pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&${params}`, { headers });
    }

    public invitationsXLS(questionnaireId: number, params: string): string {
        return `/admin/feedback-questionnaire/${questionnaireId}/invitations/xls?${params}`;
    }

    public invitationsPerUser(questionnaireId: number, userId: number, pageNumber: number, pageSize: number, sortBy: string, order: string, params: string): Observable<QuestionnaireInventionsList> {
        const headers = this.defaultHeaders;
        return this.http.get<QuestionnaireInventionsList>(`${this.basePath}/admin/feedback-questionnaire/${questionnaireId}/invitations?userId=${userId}&pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&${params}`, { headers });
    }

    removeAssessment(questionnaireId: number, body: any): Observable<any> {
        const options = {
            headers: this.defaultHeaders,
            body
        };
        return this.http.delete<any>(`${this.basePath}/admin/feedback-questionnaire/${questionnaireId}/assessment`, options);
    }

    public invitationMessage(questionnaireId: number, body: any): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.post<any>(`${this.basePath}/admin/feedback-questionnaire/${questionnaireId}/invitation-message`, body, { headers });
    }

    public assignedMessage(questionnaireId: number, body: any): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.post<any>(`${this.basePath}/admin/feedback-questionnaire/${questionnaireId}/assigned-questionnaire-message`, body, { headers });
    }

    public downloadTemplateInvitationUsersXls(body: any): Observable<HttpResponse<Blob>> {
        // @ts-disable
        // @ts-ignore
        return this.http.post<HttpResponse<Blob>>(`${this.basePath}/template/xls/feedback-invitations`, body, { ...this.bodyBlob });
    }

    public importInventionsXls(questionnaireId: number, file: any, body: any, translations: any): Observable<any> {
        const formParams = new FormData();
        formParams.append('import', file);
        formParams.append('email', body.email);
        formParams.append('invitedEmail', body.invitedEmail);
        formParams.append('type', body.type);
        formParams.append('translations', JSON.stringify(translations));
        return this.http.post<any>(`${this.basePath}/admin/feedback-questionnaire/${questionnaireId}/invitation-import`, formParams, {
            reportProgress: true,
            observe: 'events'
        });
    }

    public downloadInvitationImportXls(questionnaireId: number, body: any): Observable<HttpResponse<Blob>> {
        // @ts-disable
        // @ts-ignore
        return this.http.post<HttpResponse<Blob>>(`${this.basePath}/admin/feedback-questionnaire/${questionnaireId}/invitation-import/xls`, body, { ...this.bodyBlob });
    }

    public getDashboardInformation(): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/user/feedback/dashboard`, { headers });
    }

    public getSelectedFeedbackDashboardInformation(feedbackId: number): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/user/feedback/dashboard?feedbackId=${feedbackId}`, { headers });
    }

    public getGivenFeedbackDashboardInformation(): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/user/feedback/dashboard?given=1`, { headers });
    }

    public getFeedbackCollect(): Observable<CollectFeedback> {
        const headers = this.defaultHeaders;
        return this.http.get<CollectFeedback>(`${this.basePath}/user/feedback/collect`, { headers });
    }

    public getGiveFeedback(): Observable<GiveFeedbackArrays> {
        const headers = this.defaultHeaders;
        return this.http.get<GiveFeedbackArrays>(`${this.basePath}/user/feedback/give`, { headers });
    }

    public getGivenFeedback(): Observable<GiveFeedbackArrays> {
        const headers = this.defaultHeaders;
        return this.http.get<GiveFeedbackArrays>(`${this.basePath}/user/feedback/given`, { headers });
    }

    public getSelectedGivenFeedback(feedbackId: number): Observable<GiveFeedbackArrays> {
        const headers = this.defaultHeaders;
        return this.http.get<GiveFeedbackArrays>(`${this.basePath}/user/feedback/given/${feedbackId}`, { headers });
    }

    public getInvitation(invitationId: number): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<GiveFeedbackArrays>(`${this.basePath}/user/feedback/give/${invitationId}`, { headers });
    }

    public getFinishedFeedback(): Observable<FinishedFeedback> {
        const headers = this.defaultHeaders;
        return this.http.get<FinishedFeedback>(`${this.basePath}/user/feedback/finished`, { headers });
    }

    public rejectFeedback(body: { invitationId: number, message: string }): Observable<GiveFeedbackArrays> {
        const headers = this.defaultHeaders;
        return this.http.post<GiveFeedbackArrays>(`${this.basePath}/user/feedback/reject`, body, { headers });
    }

    public getSelfFeedbackPlayer(): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/user/feedback/self`, { headers });
    }

    public getSelfFeedbackPlayerByFeedback(feedbackId: number): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/user/feedback/self/${feedbackId}`, { headers });
    }

    public getOtherFeedbackPlayer(invitationId: number): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/user/feedback/${invitationId}`, { headers });
    }

    public updateAnswerFeedbackPlayer(body: any): Observable<GiveFeedbackArrays> {
        const headers = this.defaultHeaders;
        return this.http.post<GiveFeedbackArrays>(`${this.basePath}/user/feedback/update`, body, { headers });
    }

    public updateAnswerNoteFeedbackPlayer(body: any): Observable<GiveFeedbackArrays> {
        const headers = this.defaultHeaders;
        return this.http.post<GiveFeedbackArrays>(`${this.basePath}/user/feedback/note`, body, { headers });
    }

    public finishFeedbackPlayer(body: any): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.post<any>(`${this.basePath}/user/feedback/finish`, body, { headers });
    }

    public getUserReport(feedbackId: number): Observable<FeedbackUserReportInterface> {
        const headers = this.defaultHeaders;
        return this.http.get<FeedbackUserReportInterface>(`${this.basePath}/user/feedback-report/${feedbackId}`, { headers });
    }

    public getUserReportPDF(feedbackId: number): string {
        // const body = {
        //     images,
        //     translations
        // };
        //
        // // @ts-ignore
        // // @ts-disable
        // return this.http.post<HttpResponse<Blob>>(`${this.basePath}/user/feedback-report/${feedbackId}`, body, { observe: 'response', responseType: 'blob', headers: 'X-Downloadable:true' });
        return `/user/feedback-report/${feedbackId}`;
    }

    public shareReportForSupervisor(feedbackId: number, body: any): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.post<any>(`${this.basePath}/user/feedback-report-for-supervisor/${feedbackId}`, body, { headers });
    }

    // Organization
    public getFeedbackNameOrganization(companyId: number, questionnaireId: number): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/company/${companyId}/organization/feedback/${questionnaireId}`, { headers });
    }

    public updateUserIndividualDevTips(companyId: number, feedbackId: number, userId: number, questionnaireId: number, body: any): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.post<any>(`${this.basePath}/company/${companyId}/organization/feedback-individual-dev-tips-update/${feedbackId}/${userId}/${questionnaireId}`, body,{ headers });
    }

    public removeUserIndividualDevTips(companyId: number, feedbackId: number, userId: number): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.delete<any>(`${this.basePath}/company/${companyId}/organization/feedback-individual-dev-tips-delete/${feedbackId}/${userId}`, { headers });
    }

    public getFeedbackOrganizationResults(companyId: number, questionnaireId: number, pageNumber: number, pageSize: number, sortBy: string, order: string, params: string): Observable<OrgFeedbacksResultList> {
        const headers = this.defaultHeaders;
        return this.http.get<OrgFeedbacksResultList>(`${this.basePath}/company/${companyId}/organization/feedbacks/${questionnaireId}?&pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&${params}`, { headers });
    }

    public getFeedbackOrganizationResultsXLS(companyId: number, questionnaireId: number, params: string): string {
        return `/company/${companyId}/organization/feedbacks/${questionnaireId}/xls?${params}`;
    }

    public userQuestionnaireZIP(companyId: number,ids): string {
        return `/company/${companyId}/questionnaires-zip-download?ids=${ids}`;
    }

    public getFeedbackOrganizationUserRatings(companyId: number, questionnaireId: number, assignedUserId: number, pageNumber: any, pageSize: number, sortBy: string, order: string, params: string): Observable<OrgFeedbacksResultList> {
        const headers = this.defaultHeaders;
        return this.http.get<OrgFeedbacksResultList>(`${this.basePath}/company/${companyId}/organization/feedbacks/${questionnaireId}/${assignedUserId}?&pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&${params}`, { headers });
    }

    public getOtherUserAnswersOrganization(companyId: number, questionnaireId: number, assignedUserId: number, invitationId: number, pageNumber: number, pageSize: number, sortBy: string, order: string, params: string): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/company/${companyId}/organization/feedbacks/${questionnaireId}/${assignedUserId}/${invitationId}?&pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&${params}`, { headers });
    }

    public getUserCampaignsOrganization(companyId: number, userId: number, pageNumber: number, pageSize: number, sortBy: string, order: string, params: string): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/company/${companyId}/organization/feedbacks/user/${userId}?&pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&${params}`, { headers });
    }

    public getUserCampaignsOrganizationXLS(companyId: number, userId: number, params: string): string {
        return `/company/${companyId}/organization/feedbacks/user/${userId}/xls?${params}`;
    }

    public getStatsReportOrganization(companyId: number, feedbackId: number, param: string): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/company/${companyId}/organization/feedback-report/${feedbackId}${param}`, { headers });
    }

    public getChartStatsReportOrganization(companyId: number, feedbackId: number, param: string): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/company/${companyId}/organization/feedback-report-chart/${feedbackId}${param}`, { headers });
    }

    public getChartStatsReportTeam(loggedUserId: number, feedbackId: number, param: string): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/user/${loggedUserId}/team/feedback-report-chart/${feedbackId}${param}`, { headers });
    }

    public getUserReportOrganization(companyId: number, feedbackId: number, userId: number): Observable<FeedbackUserReportInterface> {
        const headers = this.defaultHeaders;
        return this.http.get<FeedbackUserReportInterface>(`${this.basePath}/company/${companyId}/organization/feedback-report/${feedbackId}/${userId}`, { headers });
    }

    public getUserReportOrganizationPDF(companyId: number, feedbackId: number, userId: number): string {
        // const body = {
        //     images,
        //     translations
        // };
        // // @ts-ignore
        // // @ts-disable
        // return this.http.post<HttpResponse<Blob>>(`${this.basePath}/company/${companyId}/organization/feedback-report/${feedbackId}/${userId}`, body, { observe: 'response', responseType: 'blob', headers: 'X-Downloadable:true' });
        return `/company/${companyId}/organization/feedback-report/${feedbackId}/${userId}`;
    }

    public getStatsReportOrganizationPDF(companyId: number, questionnaireId: number, param: string): string {
        // const body = {
        //     images,
        //     translations,
        //     category
        // };
        // // @ts-ignore
        // // @ts-disable
        // return this.http.post<HttpResponse<Blob>>(`${this.basePath}/company/${companyId}/organization/feedback-report/${questionnaireId}${param}`, body, { observe: 'response', responseType: 'blob', headers: 'X-Downloadable:true' });

        return `/company/${companyId}/organization/feedback-report/${questionnaireId}${param}`;
    }

    public getComparisionOrganization(companyId: number, ids: number[], groupBy = 'all', positions = null, supervisors = null, users = null, groups = null): Observable<any> {
        const headers = this.defaultHeaders;
        const body = {
            ids,
            type: groupBy,
            positions,
            supervisors,
            users,
            groups
        };
        return this.http.post<any>(`${this.basePath}/company/${companyId}/organization/feedback-compare`, body, { headers });
    }

    public getComparisionOrganizationPDF(companyId: number): string {
        // const body = {
        //     translations,
        //     ids,
        //     type: groupBy,
        //     positions,
        //     supervisors,
        //     users,
        //     groups
        // };
        // // @ts-ignore
        // // @ts-disable
        // return this.http.post<HttpResponse<Blob>>(`${this.basePath}/company/${companyId}/organization/feedback-compare/pdf`, body, { observe: 'response', responseType: 'blob', headers: 'X-Downloadable:true' });

        return `/company/${companyId}/organization/feedback-compare/pdf`;
    }

    // Team
    public getFeedbackNameTeam(loggedUserId: number, questionnaireId: number): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/user/${loggedUserId}/team/feedback/${questionnaireId}`, { headers });
    }

    public getFeedbackTeamResults(userId: number, questionnaireId: number, pageNumber: any, pageSize: any, sortBy: any, order: any, params: string): Observable<TeamFeedbacksResultList> {
        const headers = this.defaultHeaders;
        return this.http.get<TeamFeedbacksResultList>(`${this.basePath}/user/${userId}/team/feedbacks/${questionnaireId}?&pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&${params}`, { headers });
    }

    public getFeedbackTeamResultsXLS(userId: number, questionnaireId: number, params: string): string {
        return `/user/${userId}/team/feedbacks/${questionnaireId}/xls?${params}`;
    }

    public getFeedbackTeamUserRatings(userId: number, questionnaireId: number, assignedUserId: number, pageNumber: number, pageSize: number, sortBy: string, order: string, params: string): Observable<OrgFeedbacksResultList> {
        const headers = this.defaultHeaders;
        return this.http.get<OrgFeedbacksResultList>(`${this.basePath}/user/${userId}/team/feedbacks/${questionnaireId}/${assignedUserId}?&pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&${params}`, { headers });
    }

    public getOtherUserAnswersTeam(userId: number, questionnaireId: number, assignedUserId: number, invitationId: number, pageNumber: number, pageSize: number, sortBy: string, order: string, params: string): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/user/${userId}/team/feedbacks/${questionnaireId}/${assignedUserId}/${invitationId}?&pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&${params}`, { headers });
    }

    public getUserCampaignsTeam(loggedUser: number, userId: number, pageNumber: number, pageSize: number, sortBy: string, order: string, params: string): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/user/${loggedUser}/team/feedbacks/user/${userId}?&pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&${params}`, { headers });
    }

    public getUserCampaignsTeamXLS(loggedUser: number, userId: number, params: string): string {
        return `/user/${loggedUser}/team/feedbacks/user/${userId}/xls?${params}`;
    }

    public getStatsReportTeam(loggedUserId: number, feedbackId: number, param: string): Observable<FeedbackStatsReportInterface> {
        const headers = this.defaultHeaders;
        return this.http.get<FeedbackStatsReportInterface>(`${this.basePath}/user/${loggedUserId}/team/feedback-report/${feedbackId}${param}`, { headers });
    }

    public getUserReportTeam(loggedUserId: number, feedbackId: number, userId: number): Observable<FeedbackUserReportInterface> {
        const headers = this.defaultHeaders;
        return this.http.get<FeedbackUserReportInterface>(`${this.basePath}/user/${loggedUserId}/team/feedback-report/${feedbackId}/${userId}`, { headers });
    }

    public getUserReportTeamPDF(loggedUserId: number, feedbackId: number, userId: number): string {
        // const body = {
        //     images,
        //     translations
        // };
        // // @ts-ignore
        // // @ts-disable
        // return this.http.post<HttpResponse<Blob>>(`${this.basePath}/user/${loggedUserId}/team/feedback-report/${feedbackId}/${userId}`, body, { observe: 'response', responseType: 'blob', headers: 'X-Downloadable:true' });

        return `/user/${loggedUserId}/team/feedback-report/${feedbackId}/${userId}`;
    }

    public getComparisionTeam(loggedUserId: number, ids: number[], groupBy = 'all', positions = null, supervisors = null, users = null, groups = null): Observable<any> {
        const headers = this.defaultHeaders;
        const body = {
            ids,
            type: groupBy,
            positions,
            supervisors,
            users,
            groups
        };
        return this.http.post<any>(`${this.basePath}/user/${loggedUserId}/team/feedback-compare`, body, { headers });
    }

    public getComparisionTeamPDF(loggedUserId: number): string {
        // const body = {
        //     translations,
        //     ids,
        //     type: groupBy,
        //     positions,
        //     supervisors,
        //     users,
        //     groups
        // };
        // // @ts-ignore
        // // @ts-disable
        // return this.http.post<HttpResponse<Blob>>(`${this.basePath}/user/${loggedUserId}/team/feedback-compare/pdf`, body, { observe: 'response', responseType: 'blob', headers: 'X-Downloadable:true' });
        return `/user/${loggedUserId}/team/feedback-compare/pdf`;
    }

    public confirmAssessment(companyId: number, feedbackId: number, userId: number, questionnaireId: number): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.post<any>(`${this.basePath}/company/${companyId}/organization/confirm-user-assessment/${feedbackId}/${userId}/${questionnaireId}`, [],{ headers });
    }

    public confirmDevTips(companyId: number, feedbackId: number, userId: number, questionnaireId: number, body: any): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.post<any>(`${this.basePath}/company/${companyId}/organization/confirm-user-dev-tips/${feedbackId}/${userId}/${questionnaireId}`, body,{ headers });
    }
}
