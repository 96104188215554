<div class="container-fluid feedback-user-report" *ngIf="data">
    <div class="page-title">
        <back-button
                [showButtonBack]="true"
                [url]="baseRouter"
                text="{{'FeedbackStats.ParticipantReport'| translate}}: {{data.feedback.name}}"></back-button>

        <div class="page-title__right">
            <span class="page-title__right--date">
                {{'Common.Feedback'| translate}}: {{data.feedback.publicationStartDate| date: 'dd.MM.YYYY'}}
                - {{data.feedback.publicationEndDate| date: 'dd.MM.YYYY'}}
            </span>
        </div>
    </div>

    <div class="tab-links">
        <ng-container *ngFor="let link of navLinks">
            <a *ngIf="link.isVisible"
               [routerLink]="link.path"
               [ngClass]="{'active': link.isActive}">
                {{link.label | translate}}
            </a>
        </ng-container>
    </div>

    <div class="feedback-user-report__container">
        <div class="feedback-user-report__switch-download" *ngIf="data.devTips.requireConfirmationOfDevTips">
            <div class="feedback-user-report__switch-download--buttons" *ngIf="!isEdit && devTipsIsVisible && data.devTips.userIndividualDevTip">
                <button *ngIf="data.devTips.userCanChangeStatus && !data.devTips.status" (click)="confirmDevTips()"
                    class="btn confirm-button">{{'QuestionnaireCreator.RequireConfirmationOfDevTipsAccept' | translate}}</button>

                <button *ngIf="data.devTips.userCanChangeStatus && !data.devTips.status" (click)="rejectDevTips()"
                        class="btn reject-button">{{'Common.RejectButton' | translate}}</button>
                <div class="confirm-status" *ngIf="userCanEdit && !data.devTips.isConfirmed && !data.devTips.status">{{ 'QuestionnaireCreator.AwaitingApproval' | translate }}</div>
                <div class="assesment-confirmed" *ngIf="data.devTips.isConfirmed && data.devTips.status == 'confirmed'">
                    <img src="assets/icons/checked-green.svg" alt="checked-green">
                    <div>
                        <span class="label">{{'QuestionnaireCreator.ConfirmedDevTips' | translate}}</span>
                            <span class="value">{{ data.devTips.confirmedDate | date : 'dd.MM.YYYY'}}</span>
                    </div>
                </div>
                <div class="assesment-confirmed" *ngIf="!data.devTips.isConfirmed && data.devTips.status == 'rejected'">
                    <img src="assets/icons/no-checked.svg" alt="no-checked">
                    <div>
                        <span class="label">{{'QuestionnaireCreator.RejectedIndividualDevTips' | translate}}</span>
                            <span class="value">{{ data.devTips.rejectedDate | date : 'dd.MM.YYYY'}}</span>
                    </div>
                </div>
            </div>
        </div>

        <div class="section-name">{{'QuestionnaireCreator.DevelopmentTips' | translate | uppercase}}</div>
        <div class="report-section-description individual-dev-tip white-box"
             *ngIf="devTipsIsVisible">
            <h3 *ngIf="data.devTips.userIndividualDevTip || isEdit">{{'QuestionnaireCreator.IndividualDevelopmentTips' | translate}}</h3>

            <div *ngIf="!isEdit" [innerHTML]="data.devTips.userIndividualDevTip | safe: 'html'"
                 class="inner-text-html quill-content"></div>

            <div class="editor-wrapper" id="editor-wrapper">
                <label *ngIf="isEdit">{{ 'QuestionnaireCreator.Contents' | translate}} *</label>
                <div id="quill-editor" #quillEditor></div>
                <mat-error *ngIf="errorEditor" style="font-size: 12px;">{{ 'Common.FiledCanNotBeEmpty' | translate }}</mat-error>
            </div>

            <div class="individual-dev-tip__slides">
                <mat-slide-toggle
                        *ngIf="isEdit"
                        [(ngModel)]="data.devTips.userIndividualDevTipIsVisible"
                        (change)="checkValue(data.devTips.userIndividualDevTipIsVisible ? 1:0)"
                        id="isVisible"
                        color="primary">{{'QuestionnaireCreator.IndividualDevelopmentTipsVisibleForUser' | translate}}
                </mat-slide-toggle>

                <mat-slide-toggle
                        *ngIf="isEdit"
                        [(ngModel)]="data.devTips.requireConfirmationOfDevTips"
                        [disabled]="!data.devTips.userIndividualDevTipIsVisible"
                        id="requireConfirmationOfDevTips"
                        color="primary">{{'QuestionnaireCreator.RequireConfirmationOfDevTips' | translate}}
                </mat-slide-toggle>
            </div>
            <div *ngIf="isEdit" class="individual-dev-tips-buttons--info"><img src="assets/icons/info.svg" alt="checked-green" style="margin-right: 2px;"> {{ 'QuestionnaireCreator.RequireConfirmationOfDevTipsInfo' | translate }}</div>
            <div mat-dialog-actions class="dialog-footer" *ngIf="isEdit">
                <button mat-button color="primary" (click)="saveIndividualDevTip()"
                        class="dolineo-button blue-button">{{ 'Common.Save' | translate}}</button>
                <button (click)="cancelEdit()" mat-button
                        class="cancel-button">{{'Common.Cancel' | translate}}</button>
            </div>

            <button mat-button *ngIf="!isEdit && data.devTips.userIndividualDevTip === ''"
                    (click)="editIndividualTip()"
                    class="add-individual-dev-tip-button">
                <img src="assets/images/default/icon_plus.svg" alt="icon_plus">
                <span class="sm">
                    {{'QuestionnaireCreator.AddIndividualDevelopmentTips' |translate}}
                </span>
            </button>

            <div class="individual-dev-tips-buttons">
                <button mat-button *ngIf="!isEdit && data.devTips.userIndividualDevTip !== '' && userCanEdit"
                        (click)="editIndividualTip()"
                        color="primary"
                        type="button"
                        class="btn-sm btn-dolineo-icon">
                    <i class="dolineo-icon dolineo-icon-pencil2 dolineo-icon-blue"></i>
                    {{'Common.Edit' | translate}}
                </button>

                <button mat-button *ngIf="!isEdit && data.devTips.userIndividualDevTip !== '' && userCanEdit"
                        (click)="deleteIndividualTip()"
                        color="primary"
                        type="button"
                        class="btn-sm btn-dolineo-icon">
                    <i class="dolineo-icon dolineo-icon-trash dolineo-icon-blue"></i>
                    {{'Common.Delete' | translate}}
                </button>
            </div>

            <div class="individual-dev-tip__logs" *ngIf="!isEdit && data.devTips.userIndividualDevTip && data.devTips.logs.length > 0">
                <mat-accordion #accordion="matAccordion"
                               [multi]="true"
                               class="drop-down">
                    <mat-expansion-panel hideToggle [expanded]="false">
                        <mat-expansion-panel-header #panelH (click)="panelH._toggle()">
                            <mat-panel-title>
                                <button mat-button (click)="panelH._toggle()"
                                        matTooltip="{{(panelH._isExpanded()? 'Common.Collapse': 'Common.Expand') | translate}}"
                                        matTooltipPosition="below"
                                        matTooltipClass='below-top'
                                        type="button">
                                    <img [src]="panelH._isExpanded() ?
                                                            'assets/icons/arrow-full-down.svg' :
                                                            'assets/icons/arrow-full-right.svg'"
                                         [ngStyle]="{'top': panelH._isExpanded() ? '1px' : '0px'}"
                                         alt="arrow-full-right">
                                </button>
                                <span class="individual-dev-tip__logs--title">{{ 'QuestionnaireCreator.ActivityHistory' | translate }}</span>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class="individual-dev-tip__logs--single-log"
                             *ngFor="let log of data.devTips.logs">
                            <span class="created-date">{{ log.createdAt }}</span>
                            <span class="dot"></span>
                            <span class="creator">{{ log.createdBy }}:</span>
                            <span class="content">{{ log.content | translate }}</span>
                            <span class="comment">{{ log.comment }}</span>
                        </div>
                    </mat-expansion-panel>
                </mat-accordion>
            </div>
        </div>

        <div *ngIf="assignUsersBlock || this.elements.length > 0" class="section-name">{{'FeedbackUserReport.TrainingsPathsToBeImplementedAfterFeedback' | translate | uppercase}}</div>
        <div class="report-section-description assign-users-to-path-trainings white-box" *ngIf="assignUsersBlock">
            <button mat-button
                    *ngIf="assignUsersToPaths"
                    (click)="assignToPaths()">
                <i class="dolineo-icon dolineo-icon-blue dolineo-icon-add_user"></i>
                <span class="sm">
                    {{'FeedbackUserReport.AssignToPaths' |translate}}
                </span>
            </button>
            <button mat-button
                    *ngIf="assignUsersToPaths"
                    (click)="assignToTrainings()">
                <i class="dolineo-icon dolineo-icon-blue dolineo-icon-add_user"></i>
                <span class="sm">
                    {{'FeedbackUserReport.AssignToTrainings' |translate}}
                </span>
            </button>
        </div>
        <div class="user-path-trainings" *ngIf="assignUsersBlock || userVisiblePathTrainingBlock" [ngStyle]="elements.length <= 2 ? {'justify-content' : 'flex-start'} : {}">
            <app-path-training-tile *ngFor="let path of elements"
                                    [showMore]="false"
                                    (updateLikeEmit)="onFavouritePath(path)"
                                    [ngStyle]="elements.length <= 2 ? {'margin-right' : '40px'} : {}"
                                    [data]="path">
            </app-path-training-tile>
        </div>

        </div>
</div>
