import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../core/services/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '../../api/api.service';
import { HelperService } from '../../shared/services/helper.service';
import { TranslateService } from '@ngx-translate/core';
import { ClientLoginPage } from '../../shared/intefaces/client-login-page';
import { Subscription } from 'rxjs';
import { environment } from '../../../environments/environment';
import { AppLocaleService } from '../../core/services/locale.service';

@Component({
    selector: 'app-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

    loginForm: FormGroup;

    private _token: string;
    public activation: boolean;
    public tokenIsValid: boolean = null;
    layout: ClientLoginPage = null;
    subscriptions: Subscription = new Subscription();
    public apiUrl = environment.filesPath;
    subDomain: string;
    currentLang: string;

    public header = '';
    public description = '';
    public policyUrl = '';
    public termsUrl = '';

    public validation = {
        password: '',
        confirmPassword: ''
    };

    logoUrl: string;
    logoAlt: string;

    constructor(private formBuilder: FormBuilder,
                private router: Router,
                private route: ActivatedRoute,
                private authService: AuthService,
                private _api: ApiService,
                protected _helper: HelperService,
                protected translate: TranslateService,
                private _localeService: AppLocaleService) {
        if (environment.appName === 'dolineo') {
            this.logoUrl = 'assets/images/logo/logo_dolineo.svg';
            this.logoAlt = 'dolineo_icon';
        } else if (environment.appName === 'ontigo') {
            this.logoUrl = 'assets/images/logo/logo_ontigo.svg';
            this.logoAlt = 'ontigo_icon';
        }

        this.startedValue();
    }

    startedValue() {
        this.subDomain = window.location.hostname.replace(environment.domain, '').replace('.', '').toLowerCase();

        this.subscriptions.add(this._api.layout.subscribe((value: ClientLoginPage) => {
            this.layout = value;

            if (this.layout !== null) {
                this.setLang(this.getBrowserLang());
            }
        }));
    }

    ngOnInit() {
        this.initForm();
        this.formControlValueChanged();
        this.route.queryParams.subscribe(params => {
            this._token = params['token'];
            this.activation = params['a'] === 'a';
            this.checkToken();
        });
    }

    getBrowserLang() {
        const selectedLang = localStorage.getItem('startLanguage');
        if (!selectedLang) {
            let language: string = window.navigator.language.split('-')[0];
            if (!language) {
                language = 'pl'
            }
            return language;
        }
        return selectedLang;
    }

    checkToken() {
        this.authService.checkToken(this._token).subscribe((status: boolean) => {
            this.tokenIsValid = status;
        });
    }

    initForm() {
        this.loginForm = this.formBuilder.group({
            password: ['', [Validators.required]],
            confirmPassword: ['', [Validators.required]]
        });
    }

    formControlValueChanged() {
        const passwordControl = this.loginForm.get('password');
        const confirmPasswordControl = this.loginForm.get('confirmPassword');

        this.loginForm.get('password').valueChanges.subscribe(
            (value: string) => {
                const errors = this._helper.passwordValidation(value);
                if (value === '' || (errors.includes('PasswordValidationLength') && errors.length === 1)) {
                    this.validation.password = this.translate.instant('Common.FiledCanNotBeEmpty');
                    passwordControl.setErrors({ 'invalid': true });
                } else if (errors.length >= 1) {
                    this.validation.password = this.translate.instant('Profile.PasswordValidation');
                    passwordControl.setErrors({ 'invalid': true });
                }

                if (confirmPasswordControl.value !== '' && confirmPasswordControl.value !== value) {
                    confirmPasswordControl.setErrors({ 'invalid': true });
                } else {
                    confirmPasswordControl.setErrors({ 'invalid': false });
                }
                confirmPasswordControl.updateValueAndValidity()
            });

        confirmPasswordControl.valueChanges.subscribe(
            (value: string) => {
                if (value !== passwordControl.value) {
                    this.validation.confirmPassword = this.translate.instant('Profile.PassMissmatch');
                    confirmPasswordControl.setErrors({ 'invalid': true });
                }
            });
    }

    submit() {
        if (!this.loginForm.valid) {
            return;
        }
        const sub = this.subDomain ? this.subDomain : '';
        const value = { ...this.loginForm.value, token: this._token, subdomain: sub }

        this.loginForm.disable();
        this.authService.resetPassword(value).subscribe((status: boolean) => {
            if (status) {
                if (this.activation) {
                    this.authService.correctActivationPassword = true;
                } else {
                    this.authService.correctResetPassword = true;
                }

                this.loginForm.reset();
                this.router.navigateByUrl('/login');
            }
        });
    }

    setLang(lang: string) {
        if (this.currentLang !== lang) {
            this.currentLang = lang;
            this.translate.setDefaultLang(lang);
            this.translate.use(lang);
            this.subscriptions.add(this.translate.reloadLang(lang).subscribe(() => {
                this._helper.createTitle(['ResetPassword.AccountActivation'])
            }));
            localStorage.setItem('startLanguage', lang);
            this._localeService.setLocale(lang);
            if (this.layout) {
                this.header = this.layout.headers[lang] ? this.layout.headers[lang] : '';
                this.description = this.layout.descriptions[lang] ? this.layout.descriptions[lang] : ''
            }
        }
    }
}
