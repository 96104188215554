import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable()
export class LibraryService {

    protected basePath = environment.apiPath;
    public defaultHeaders = new HttpHeaders();
    public baseSubject: BehaviorSubject<any> = new BehaviorSubject<any>('');
    public trainingType: Subject<any> = new Subject<any>();
    public file: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public errorScorm: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    public errorH5P: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    public isNavBar: Subject<boolean> = new Subject<boolean>();

    constructor(protected http: HttpClient) {
        this.defaultHeaders = this.defaultHeaders.append('Accept', 'application/json');
        this.defaultHeaders = this.defaultHeaders.append('Content-Type', 'application/json');
    }

    public addPath(body: any, setCoverNull: boolean): Observable<any> {
        const headers = this.defaultHeaders;
        if (setCoverNull) {
            body.cover = null;
        }
        return this.http.post<any>(`${this.basePath}/path`, body, { headers });
    }

    public paths(idUser: number, params: string, pageNumber: number, pageSize: number): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/user/${idUser}/path/library?pageNumber=${pageNumber}&pageSize=${pageSize}${params}`, { headers });
    }

    public pathUsers(idUser: number, pathId: number): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/user/${idUser}/team/path/${pathId}/user-checklist`, { headers });
    }

    public pathUsersGroup(idUser: number, paths: any): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/user/${idUser}/team/path/group-user-checklist?${paths}`,{ headers });
    }

    public pathUsersAssigned(idUser: number, trainingId: number): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/user/${idUser}/team/path/${trainingId}/assigned`, { headers });
    }

    public pathsUsersAssigned(idUser: number, trainingId: number): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/user/${idUser}/team/path/${trainingId}/assigned`, { headers });
    }

    public getPath(pathId: number | string): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/path/${pathId}`, { headers });
    }

    public assignPath(pathId: number, body: any): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.post<any>(`${this.basePath}/path/${pathId}/assign`, body, { headers });
    }

    public assignPaths(body: any): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.post<any>(`${this.basePath}/path/assign`, body, { headers });
    }

    public unAssignSelfPath(pathId: number): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.post<any>(`${this.basePath}/path/${pathId}/unassign/self`, {}, { headers });
    }

    public assignSelfPath(pathId: number): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.post<any>(`${this.basePath}/path/${pathId}/assign/self`, {}, { headers });
    }

    public pathCheckName(body: any): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.post<any>(`${this.basePath}/path/check-name`, body, { headers });
    }

    public downloadPathSelf(params: string): string {
        return `/admin/path/xls?${params}`;
    }

    public downloadPathDolineo(params: string): string {
        return `/admin/dolineo-path/xls?${params}`;
    }

    public downloadTrainingSelf(params: string): string {
        return `/admin/training/xls?${params}`;
    }

    public downloadTrainingDolineo(params: string): string {
        return `/admin/dolineo-training/xls?${params}`;
    }

    public downloadPoolSelf(params: string): string {
        return `/admin/pools/xls?${params}`;
    }

    public downloadPoolDolineo(params: string): string {
        return `/admin/dolineo-pools/xls?${params}`;
    }

    public addTraining(body: any, setCoverNull: boolean): Observable<any> {
        const headers = this.defaultHeaders;
        if (setCoverNull) {
            body.cover = null;
        }
        return this.http.post<any>(`${this.basePath}/training`, body, { headers });
    }

    public trainings(idUser: number, params: string, pageNumber: number, pageSize: number): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/user/${idUser}/training/library?pageNumber=${pageNumber}&pageSize=${pageSize}${params}`, { headers });
    }

    public getTraining(trainingId: number | string): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/training/${trainingId}`, { headers });
    }

    public trainingUsers(idUser: number, trainingId: number): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/user/${idUser}/team/training/${trainingId}/user-checklist`, { headers });
    }

    public trainingUsersGroup(idUser: number, trainings: any): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/user/${idUser}/team/training/group-user-checklist?${trainings}`, { headers });
    }

    public trainingUsersAssigned(idUser: number, trainingId: number): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/user/${idUser}/team/training/${trainingId}/assigned`, { headers });
    }

    public assignTraining(trainingId: number, body: any): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.post<any>(`${this.basePath}/training/${trainingId}/assign`, body, { headers });
    }

    public assignTrainings(body: any): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.post<any>(`${this.basePath}/training/assign`, body, { headers });
    }

    public unAssignSelfTraining(trainingId: number): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.post<any>(`${this.basePath}/training/${trainingId}/unassign/self`, {}, { headers });
    }

    public assignSelfTraining(trainingId: number): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.post<any>(`${this.basePath}/training/${trainingId}/assign/self`, {}, { headers });
    }

    public trainingCheckName(body: any): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.post<any>(`${this.basePath}/training/check-name`, body, { headers });
    }

    public pools(idUser: number, params: string, pageNumber: number, pageSize: number): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/user/${idUser}/pool/library?pageNumber=${pageNumber}&pageSize=${pageSize}${params}`, { headers });
    }

    public getPool(poolId: number | string, isPreview: boolean = false): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/pools/${poolId}?isPreview=${isPreview}`, { headers });
    }

    public getPoolName(poolId: number | string): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/pools/${poolId}/name`, { headers });
    }

    public poolUsers(poolId: number): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/pool/${poolId}/user-checklist`, { headers });
    }

    public poolUsersAssigned(poolId: number): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/pool/${poolId}/assigned`, { headers });
    }

    public assignPool(poolId: number, body: any): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.post<any>(`${this.basePath}/pool/${poolId}/assign`, body, { headers });
    }

    public downloadH5p(id): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/h5p/download/${id}`, { headers });
    }

    public assignCarerList(objectType: string, objectId?: number, onlyAssigned: boolean = false): Observable<any> {
        const headers = this.defaultHeaders;
        const paramId = objectId? `&id=${objectId}` : '';
        const url = `${this.basePath}/content/carers/assigned_list?type=${objectType}&assigned=${onlyAssigned}${paramId}`
        return this.http.get<any>(url, { headers });
    }

    public assignCarerListArticle(params?: string): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/content/carers/assigned_list?type=knowledge_base_entry${params? params : ''}`, { headers });
    }

    public assignCarerAction(body: any): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.post<any>(`${this.basePath}/content/carers/assign`, body, { headers });
    }
}
