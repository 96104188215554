import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ApiService } from '../../../api/api.service';
import { HelperService } from '../../services/helper.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { UsersVisibilityDialogComponent } from '../users-visibility-dialog/users-visibility-dialog.component';
import * as moment from 'moment';
import { DeviceDetectorService } from 'ngx-device-detector';
import {ChoiceBaseMultiPathTrainingDialogComponent} from '../choice-base-multi-path-training-dialog/choice-base-path-training-dialog.component';
import {GroupAddRemoveVisibilityDialogComponent} from '../group-add-remove-visibility-dialog/group-add-remove-visibility-dialog.component';

@Component({
    selector: 'visibility-dialog',
    templateUrl: './visibility-group-dialog.component.html',
    styleUrls: ['./visibility-group-dialog.component.scss']
})
export class VisibilityGroupDialogComponent implements OnInit {
    step = 0;

    public assignedGroups: any[] = [];
    public assignedPositions: any[] = [];
    public assignedUsers: any[] = [];

    public unassignedUsers: any[] = [];
    public unassignedPositions: any[] = [];
    public unassignedGroups: any[] = [];

    usersColumns: any[] = [];
    positionsColumns: any[] = [];
    groupsColumns: any[] = [];

    usersColumnsAvailability: any[] = [];
    positionsColumnsAvailability: any[] = [];
    groupsColumnsAvailability: any[] = [];

    hasUsers: boolean;
    hasPositions: boolean;
    hasGroups: boolean;

    usersSelectedToAdd: any[] = [];
    positionsSelectedToAdd: any[] = [];
    groupsSelectedToAdd: any[] = [];
    usersSelectedToRemove: any[] = [];
    positionsSelectedToRemove: any[] = [];
    groupsSelectedToRemove: any[] = [];
    selectedSectionName: string = 'users';

    users: any[] = [];
    userToPreview: any[] = [];

    positions: any[] = [];

    translations = {
        title: '',
        accessAdded: 'AccessAdded',
        accessRemoved: 'AccessRemoved'
    };

    public hasPermissions: boolean = false;
    activeTab: string = 'unassign';
    ids: any;

    constructor(public dialogRef: MatDialogRef<VisibilityGroupDialogComponent>,
                private _toastrService: ToastrService,
                private api: ApiService,
                public _helper: HelperService,
                private _dialog: MatDialog,
                private deviceService: DeviceDetectorService,
                private _translate: TranslateService,
                @Inject(MAT_DIALOG_DATA) public data: any) {
    }

    ngOnInit(): void {
        this.hasPermissions = this._helper.hasPermissions([
            'content:path:assign',
            'content:training:assign',
            'content:pools:assign',
            'content:survey:assign',
            'content:article-category:assign',
            'carer:article:manage',
            'carer:article-category:manage',
            'carer:path:manage',
            'carer:training:manage',
            'carer:pool:manage',
            'carer:survey:manage',
            'content:gamification:assign',
            'carer:gamification:manage',
        ]);

        this.data.users.forEach(u => {
            u.positionsArray = [];
            if (u.position) {
                u.positionsArray = [u.position];
            }
        });

        this.data.positions.forEach(u => {
            u.positionsArray = [u.name];
        });

        let transDict = '';
        if(this.data.assign) {
            if (this.data.type === 'path') {
                transDict = 'Paths.SharePath';
            } else if (this.data.type === 'training') {
                transDict = 'Training.ShareTraining';
            }
        }else{
            if (this.data.type === 'path') {
                transDict = 'Paths.RemoveAccessToPaths';
            } else if (this.data.type === 'training') {
                transDict = 'Training.RemoveAccessToTrainings';
            }
        }

        this.translations.title = transDict;
        this.translations.accessAdded = this._translate.instant(transDict + this.translations.accessAdded);
        this.translations.accessRemoved = this._translate.instant(transDict + this.translations.accessRemoved);

        this.setAssignedUnassigned();

        this.api.Company.positions(
            this._helper.getUser().companyId,
            1,
            9999,
            'name',
            'asc',
            '').subscribe((res) => {
            this.positions = res.map(obj => {
                return {
                    id: obj.name,
                    value: obj.name
                };
            });
            this.createUsersColumn();
            this.createPositionsColumn();
            this.createGroupsColumn();
            this.createPoolsColumns();
        });
    }

    createPoolsColumns() {
        if (this.data.type === 'pool' || this.data.type === 'survey') {
            this.usersColumnsAvailability.pop();
            this.positionsColumnsAvailability.pop();
            this.groupsColumnsAvailability.pop();

            this.usersColumnsAvailability = [
                ...this.usersColumnsAvailability,
                {
                    index: 'startDate',
                    name: 'Common.RealisationFrom',
                    searchable: true,
                    sortable: true,
                    visible: true,
                    value: '',
                    defaultValue: '',
                    type: 'DATE_RANGE',
                    valueRange: null,
                    timePicker: true,
                    locale: {
                        format: 'YYYY-MM-DD HH:mm',
                        displayFormat: 'DD.MM.YYYY, HH:mm'
                    },
                    css: {
                        'min-width': '130px',
                        'width': '130px',
                        'padding-right': '20px'
                    },
                    render: (v) => {
                        return v.startDate ? moment(v.startDate).format('DD.MM.YYYY, HH:mm') : '';
                    }
                },
                {
                    index: 'endDate',
                    name: 'Common.RealisationTo',
                    searchable: true,
                    sortable: true,
                    visible: true,
                    value: '',
                    defaultValue: '',
                    type: 'DATE_RANGE',
                    valueRange: null,
                    timePicker: true,
                    locale: {
                        format: 'YYYY-MM-DD HH:mm',
                        displayFormat: 'DD.MM.YYYY  HH:mm'
                    },
                    css: {
                        'min-width': '130px',
                        'width': '130px',
                        'padding-right': '20px',
                    },
                    render: (v) => {
                        return v.endDate ? moment(v.endDate).format('DD.MM.YYYY, HH:mm') : '';
                    }
                },
                {
                    index: 'isMandatory',
                    name: 'Pool.Mandatory',
                    value: '',
                    defaultValue: '',
                    type: 'ARRAY',
                    sortable: true,
                    searchable: true,
                    visible: true,
                    multiple: false,
                    options: [
                        { id: true, value: 'Common.Yes' },
                        { id: false, value: 'Common.No' }
                    ],
                    css: {
                        'min-width': '100px',
                        'width': '100px',
                        'padding-right': '20px'
                    },
                    render: (v) => {
                        return v.isMandatory ? this._translate.instant('Common.Yes') : this._translate.instant('Common.No');
                    }
                }
            ];

            this.positionsColumnsAvailability = [
                ...this.positionsColumnsAvailability,
                {
                    index: 'startDate',
                    name: 'Common.RealisationFrom',
                    searchable: true,
                    sortable: true,
                    visible: true,
                    value: '',
                    defaultValue: '',
                    type: 'DATE_RANGE',
                    valueRange: null,
                    timePicker: true,
                    locale: {
                        format: 'YYYY-MM-DD HH:mm',
                        displayFormat: 'DD.MM.YYYY  HH:mm'
                    },
                    css: {
                        'min-width': '130px',
                        'width': '130px',
                        'padding-right': '20px'
                    },
                    render: (v) => {
                        return v.startDate ? moment(v.startDate).format('DD.MM.YYYY, HH:mm') : '';
                    }
                },
                {
                    index: 'endDate',
                    name: 'Common.RealisationTo',
                    searchable: true,
                    sortable: true,
                    visible: true,
                    value: '',
                    defaultValue: '',
                    type: 'DATE_RANGE',
                    valueRange: null,
                    timePicker: true,
                    locale: {
                        format: 'YYYY-MM-DD HH:mm',
                        displayFormat: 'DD.MM.YYYY  HH:mm'
                    },
                    css: {
                        'min-width': '130px',
                        'width': '130px',
                        'padding-right': '20px'
                    },
                    render: (v) => {
                        return v.endDate ? moment(v.endDate).format('DD.MM.YYYY, HH:mm') : '';
                    }
                },
                {
                    index: 'isMandatory',
                    name: 'Pool.Mandatory',
                    value: '',
                    defaultValue: '',
                    type: 'ARRAY',
                    sortable: true,
                    searchable: true,
                    visible: true,
                    multiple: false,
                    options: [
                        { id: true, value: 'Common.Yes' },
                        { id: false, value: 'Common.No' }
                    ],
                    css: {
                        'min-width': '100px',
                        'width': '100px',
                        'padding-right': '20px'
                    },
                    render: (v) => {
                        return v.isMandatory ? this._translate.instant('Common.Yes') : this._translate.instant('Common.No');
                    }
                }
            ];

            this.groupsColumnsAvailability = [
                ...this.groupsColumnsAvailability,
                {
                    index: 'startDate',
                    name: 'Common.RealisationFrom',
                    searchable: true,
                    sortable: true,
                    visible: true,
                    value: '',
                    defaultValue: '',
                    type: 'DATE_RANGE',
                    valueRange: null,
                    timePicker: true,
                    locale: {
                        format: 'YYYY-MM-DD HH:mm',
                        displayFormat: 'DD.MM.YYYY  HH:mm'
                    },
                    css: {
                        'min-width': '130px',
                        'width': '130px',
                        'padding-right': '20px'
                    },
                    render: (v) => {
                        return v.startDate ? moment(v.startDate).format('DD.MM.YYYY, HH:mm') : '';
                    }
                },
                {
                    index: 'endDate',
                    name: 'Common.RealisationTo',
                    searchable: true,
                    sortable: true,
                    visible: true,
                    value: '',
                    defaultValue: '',
                    type: 'DATE_RANGE',
                    valueRange: null,
                    timePicker: true,
                    locale: {
                        format: 'YYYY-MM-DD HH:mm',
                        displayFormat: 'DD.MM.YYYY  HH:mm'
                    },
                    css: {
                        'min-width': '130px',
                        'width': '130px',
                        'padding-right': '20px'
                    },
                    render: (v) => {
                        return v.endDate ? moment(v.endDate).format('DD.MM.YYYY, HH:mm') : '';
                    }
                },
                {
                    index: 'isMandatory',
                    name: 'Pool.Mandatory',
                    value: '',
                    defaultValue: '',
                    type: 'ARRAY',
                    sortable: true,
                    searchable: true,
                    visible: true,
                    multiple: false,
                    options: [
                        { id: true, value: 'Common.Yes' },
                        { id: false, value: 'Common.No' }
                    ],
                    css: {
                        'min-width': '100px',
                        'width': '100px',
                        'padding-right': '20px',
                    },
                    render: (v) => {
                        return v.isMandatory ? this._translate.instant('Common.Yes') : this._translate.instant('Common.No');
                    }
                }
            ]
        }
    }

    setAssignedUnassigned() {
        this.assignedGroups = this.data.groups.filter(value => value.isAssigned);
        this.assignedPositions = this.data.positions.filter(value => value.isAssigned);
        this.assignedUsers = this.data.users.filter(value => value.isAssigned);

        this.unassignedGroups = this.data.groups.filter(value => !value.isAssigned);
        this.unassignedPositions = this.data.positions.filter(value => !value.isAssigned);
        this.unassignedUsers = this.data.users.filter(value => !value.isAssigned);

        this.hasUsers = this.data.users.length > 0;
        this.hasPositions = this.data.positions.length > 0;
        this.hasGroups = this.data.groups.length > 0;
    }

    changeSelectedPaths(){
        this._helper.createTitle(['Menu.ContentManagement', 'PathCreator.Header'])
        this.api.User.getDolineoCreatorPaths(
            'name',
            'asc',
            ''
        ).subscribe(res => {
                this._dialog.open(ChoiceBaseMultiPathTrainingDialogComponent, {
                    disableClose: true,
                    autoFocus: false,
                    data: {
                        type: 'multi-path',
                        hasBaseObjects: !!res.elements.length,
                        ids: this.data.ids,
                        isDolineo: 1
                    },
                    width: '820px',
                }).afterClosed().subscribe((data) => {
                    if(data.selectedItems.length > 0){
                        this.data.names = this.getSelectedPathsName(data.selectedItems);
                    }else{
                        this.data.ids = [];
                        this.data.names = '';
                    }
                });
            }
        );
    }

    changeSelectedTrainings(){
        this._helper.createTitle(['Menu.ContentManagement', 'TrainingsCreator.Header'])
        this.api.User.getDolineoCreatorTrainings(
            'name',
            'asc',
            ''
        ).subscribe(res => {
                this._dialog.open(ChoiceBaseMultiPathTrainingDialogComponent, {
                    disableClose: true,
                    autoFocus: false,
                    data: {
                        type: 'training',
                        hasBaseObjects: !!res.elements.length,
                        ids: this.data.ids,
                        isDolineo: 1
                    },
                    width: '820px',
                }).afterClosed().subscribe((data) => {
                    if(data.selectedItems.length > 0){
                        this.data.names = this.getSelectedPathsName(data.selectedItems);
                    }else{
                        this.data.ids = [];
                        this.data.names = '';
                    }
                });
            }
        );
    }

    getSelectedPathsName(selectedItems: any){
        let names = '';
        let i = 0;
        this.data.ids = [];
        selectedItems.forEach((e)=>{
            this.data.ids.push(e);
            if (i > 0) {
                names += ', ';
            }
            names += e.name;
            i++;
        });
        return names;
    }

    setStep(index: number, name: string) {
        this.selectedSectionName = name;
        this.step = this.step === index ? -1 : index;
    }

    createUsersColumn() {
        this.usersColumns = [
            {
                index: 'userName',
                name: 'Common.FirstNameLastName',
                searchable: true,
                sortable: true,
                visible: true,
                value: '',
                defaultValue: '',
                type: 'STRING',
                css: {
                    'min-width': '150px',
                    'padding-right': '20px'
                },
                class: 'ft-auto',
            },
            {
                index: 'supervisor',
                name: 'Common.Supervisor',
                searchable: true,
                sortable: true,
                visible: true,
                value: '',
                defaultValue: '',
                type: 'STRING',
                css: {
                    'min-width': '139px',
                    'width': '139px',
                    'padding-right': '20px'
                }
            },
            {
                index: 'position',
                name: 'Common.Position',
                searchable: true,
                visible: true,
                sortable: true,
                value: '',
                defaultValue: '',
                type: 'ARRAY',
                multiple: true,
                isArrayInside: true,
                arrayInside: 'positionsArray',
                options: [...this.positions],
                css: {
                    'min-width': '137px',
                    'width': '137px',
                    'padding-right': '20px'
                }
            }
        ];

        this.usersColumnsAvailability = [
            {
                index: 'userName',
                name: 'Common.FirstNameLastName',
                searchable: true,
                sortable: true,
                visible: true,
                value: '',
                defaultValue: '',
                type: 'STRING',
                css: {
                    'min-width': '141px',
                    'width': '141px',
                    'padding-right': '20px'
                },
                class: 'ft-auto',
                render: (v) => {
                    if (v.isNew) {
                        return '<div>' + v.userName + '</div>' + `<span class="new-brand"> ${this._translate.instant('AssignDialog.NewUser')} </span>`;
                    } else {
                        return '<div class="cut-text">' + v.userName + '</div>';
                    }
                }
            },
            {
                index: 'supervisor',
                name: 'Common.Supervisor',
                searchable: true,
                sortable: true,
                visible: true,
                value: '',
                defaultValue: '',
                type: 'STRING',
                css: {
                    'min-width': '139px',
                    'width': '139px',
                    'padding-right': '20px'
                }
            },
            {
                index: 'position',
                name: 'Common.Position',
                searchable: true,
                visible: true,
                sortable: true,
                value: '',
                defaultValue: '',
                type: 'ARRAY',
                multiple: true,
                isArrayInside: true,
                arrayInside: 'positionsArray',
                options: [...this.positions],
                css: {
                    'min-width': '137px',
                    'width': '137px',
                    'padding-right': '20px'
                }
            }
        ];
    }

    createPositionsColumn() {
        this.positionsColumns = [
            {
                index: 'name',
                name: 'Common.Position',
                searchable: true,
                visible: true,
                sortable: true,
                value: '',
                defaultValue: '',
                type: 'ARRAY',
                multiple: true,
                isArrayInside: true,
                arrayInside: 'positionsArray',
                options: [...this.positions],
                css: {
                    'padding-left': '20px',
                    'min-width': '150px',
                    'padding-right': '20px'
                },
                class: 'ft-auto',
                render: (v) => {
                    if (v.isNew) {
                        return '<div>' + v.name + '</div>' + `<span class="new-brand"> ${this._translate.instant('AssignDialog.NewPosition')} </span>`;
                    } else {
                        return v.name;
                    }
                }
            },
            {
                index: 'numberOfUsers',
                name: 'Common.NumberOfPeople',
                searchable: true,
                sortable: true,
                visible: true,
                value: '',
                defaultValue: '',
                valueRange: null,
                type: 'NUMBER',
                hasMinutes: false,
                css: {
                    'width': '150px',
                    'min-width': '150px',
                    'padding-right': '20px'
                },
            }
        ];

        this.positionsColumnsAvailability = [
            {
                index: 'name',
                name: 'Common.Position',
                searchable: true,
                visible: true,
                sortable: true,
                value: '',
                defaultValue: '',
                type: 'ARRAY',
                multiple: true,
                isArrayInside: true,
                arrayInside: 'positionsArray',
                options: [...this.positions],
                css: {
                    'padding-left': '20px',
                    'min-width': '150px',
                    'padding-right': '20px'
                },
                class: 'ft-auto',
                render: (v) => {
                    if (v.isNew) {
                        return '<div>' + v.name + '</div>' + `<span class="new-brand"> ${this._translate.instant('AssignDialog.NewPosition')} </span>`;
                    } else {
                        return v.name;
                    }
                }
            },
            {
                index: 'numberOfUsers',
                name: 'Common.NumberOfPeople',
                searchable: true,
                sortable: true,
                visible: true,
                value: '',
                defaultValue: '',
                valueRange: null,
                type: 'NUMBER',
                hasMinutes: false,
                css: {
                    'width': '150px',
                    'min-width': '150px',
                    'padding-right': '20px'
                }
            }
        ];
    }

    createGroupsColumn() {
        this.groupsColumns = [
            {
                index: 'name',
                name: 'Common.Group',
                searchable: true,
                sortable: true,
                visible: true,
                value: '',
                defaultValue: '',
                type: 'STRING',
                css: {
                    'padding-left': '20px',
                    'min-width': '150px',
                    'padding-right': '20px'
                },
                class: 'ft-auto'
            },
            {
                index: 'numberOfUsers',
                name: 'Common.NumberOfPeople',
                searchable: true,
                sortable: true,
                visible: true,
                value: '',
                defaultValue: '',
                valueRange: null,
                type: 'NUMBER',
                hasMinutes: false,
                css: {
                    'width': '150px',
                    'min-width': '150px',
                    'padding-right': '20px'
                }
            }
        ];
        this.groupsColumnsAvailability = [
            {
                index: 'name',
                name: 'Common.Group',
                searchable: true,
                sortable: true,
                visible: true,
                value: '',
                defaultValue: '',
                type: 'STRING',
                css: {
                    'padding-left': '20px',
                    'min-width': '150px',
                    'padding-right': '20px'
                },
                class: 'ft-auto',
                render: (v) => {
                    if (v.isNew) {
                        return '<div>' + v.name + '</div>' + `<span class="new-brand"> ${this._translate.instant('AssignDialog.NewGroup')} </span>`;
                    } else {
                        return v.name;
                    }
                }
            },
            {
                index: 'numberOfUsers',
                name: 'Common.NumberOfPeople',
                searchable: true,
                sortable: true,
                visible: true,
                value: '',
                defaultValue: '',
                valueRange: null,
                type: 'NUMBER',
                hasMinutes: false,
                css: {
                    'width': '150px',
                    'min-width': '150px',
                    'padding-right': '20px'
                }
            }
        ];
    }

    assign() {
        this._dialog.open(GroupAddRemoveVisibilityDialogComponent, {
            disableClose: true,
            width: this.data.type === 'survey' || this.data.type === 'pool' ? '560px' : '540px',
            panelClass: 'assign-unassign-popup',
            data: {
                type: this.data.type,
                pathsSelected: this.data.ids,
                usersSelected: this.assignedUsers,
                positionsSelected: this.assignedPositions,
                groupsSelected: this.assignedGroups,
                selectedTable: this.selectedSectionName,
                headerText: this.data.type === 'training' ? 'Training.ShareTraining' : 'Paths.SharePath',
                pathsContentText: this.data.type === 'training' ? 'TrainingCreator.SelectedTrainings' : 'PathCreator.SelectedPaths',
                usersContentText: 'AddRemoveVisibility.SelectedUsers',
                positionsContentText: 'AddRemoveVisibility.SelectedPositions',
                groupsContentText: 'AddRemoveVisibility.SelectedGroups',
                status: this.data.assign,
                id: this.data.id
            }
        }).afterClosed().subscribe(val => {
            if (val) {
                if (this.deviceService.isDesktop()) {
                    this._toastrService.success(this._translate.instant(this.data.toastrAssign));
                }
                this.dialogRef.close();
            }
        })
    }

    unassign() {
        this._dialog.open(GroupAddRemoveVisibilityDialogComponent, {
            disableClose: true,
            width: this.data.type === 'survey' || this.data.type === 'pool' ? '560px' : '540px',
            panelClass: 'assign-unassign-popup',
            data: {
                type: this.data.type,
                pathsSelected: this.data.ids,
                usersSelected: this.assignedUsers,
                positionsSelected: this.assignedPositions,
                groupsSelected: this.assignedGroups,
                selectedTable: this.selectedSectionName,
                headerText: this.data.assign ? (this.data.type === 'training' ? 'Training.ShareTraining' : 'Paths.SharePath') : (this.data.type === 'training' ? 'Training.RemoveAccessToTrainings' : 'Paths.RemoveAccessToPaths'),
                pathsContentText: this.data.type === 'training' ? 'TrainingCreator.SelectedTrainings' : 'PathCreator.SelectedPaths',
                usersContentText: 'AddRemoveVisibility.SelectedUsers',
                positionsContentText: 'AddRemoveVisibility.SelectedPositions',
                groupsContentText: 'AddRemoveVisibility.SelectedGroups',
                status: this.data.assign,
                id: this.data.id
            }
        }).afterClosed().subscribe(val => {
            if (val) {
                if (this.deviceService.isDesktop()) {
                    this._toastrService.success(this._translate.instant(this.data.toastrAssign));
                }
                this.dialogRef.close();
            }
        })
    }

    selectItems(content: string, changeDate: boolean = false) {
        if (this.selectedSectionName === 'users') {
            this.assignedUsers = [...this.usersSelectedToAdd, ...this.assignedUsers];
            this.unassignedUsers = this.unassignedUsers.filter(item => !this.assignedUsers.find(ele => ele.userName === item.userName));
            this.usersSelectedToAdd = [];
        }

        if (this.selectedSectionName === 'positions') {
            this.assignedPositions = [...this.positionsSelectedToAdd, ...this.assignedPositions];
            this.unassignedPositions = this.unassignedPositions.filter(item => !this.assignedPositions.find(ele => ele.name === item.name))
            this.positionsSelectedToAdd = [];
        }

        if (this.selectedSectionName === 'groups') {
            this.assignedGroups = [...this.groupsSelectedToAdd, ...this.assignedGroups];
            this.unassignedGroups = this.unassignedGroups.filter(item => !this.assignedGroups.find(ele => ele.name === item.name))
            this.groupsSelectedToAdd = [];
        }

        if (this.deviceService.isDesktop()) {
            // this._toastrService.success(this._translate.instant(this.data.toastrAssign));
        }
    }

    deselectItems(content: string) {
        if (this.selectedSectionName === 'users') {
            this.unassignedUsers = [...this.usersSelectedToRemove, ...this.unassignedUsers];
            this.assignedUsers = this.assignedUsers.filter(item => !this.usersSelectedToRemove.find(ele => ele.userName === item.userName))
            this.usersSelectedToRemove = [];
        }

        if (this.selectedSectionName === 'positions') {
            this.unassignedPositions = [...this.positionsSelectedToRemove, ...this.unassignedPositions];
            this.assignedPositions = this.assignedPositions.filter(item => !this.positionsSelectedToRemove.find(ele => ele.name === item.name))
            this.positionsSelectedToRemove = [];
        }

        if (this.selectedSectionName === 'groups') {
            this.unassignedGroups = [...this.groupsSelectedToRemove, ...this.unassignedGroups];
            this.assignedGroups = this.assignedGroups.filter(item => !this.groupsSelectedToRemove.find(ele => ele.name === item.name))
            this.groupsSelectedToRemove = [];
        }

        if (this.deviceService.isDesktop()) {
            // this._toastrService.success(this._translate.instant(this.data.toastrUnassign));
        }
    }

    selectedAddItems(items: [], content: string) {
        this[content + 'SelectedToAdd'] = [...items];
        this[content + 'SelectedToAdd'].forEach((item) => {
            item.selected = false;
        });
    }

    selectedRemoveItems(items: [], content: string) {
        this[content + 'SelectedToRemove'] = [...items];
        this[content + 'SelectedToRemove'].forEach((item) => {
            item.selected = false;
        });
    }

    showUsers() {
        if (this.userToPreview.length) {
            this.sortByAccess();
            this._dialog.open(UsersVisibilityDialogComponent, {
                    disableClose: true,
                    width: '90vw',
                    maxWidth: '90vw',
                    height: '90vh',
                    panelClass: 'assign-unassign-popup',
                    data: {
                        dataToAssign: this.data,
                        headerText: 'AssignDialog.AssignedPreview',
                        name: this.data.name,
                        items: this.userToPreview,
                        isCustomTable: true,
                        type: this.data.type
                    }
                }
            );
        }
    }

    sortByAccess() {
        if (this.data.type === 'pool' || this.data.type === 'survey') {
            this.userToPreview.forEach(value => {
                value.assigned.sort((a, b) => {
                    const dateA = a.startDate ? new Date(a.startDate) : new Date(8640000000000000)
                    const dateB = b.startDate ? new Date(b.startDate) : new Date(8640000000000000)
                    return dateA.getTime() - dateB.getTime();
                });
            })
        } else {
            this.userToPreview.forEach(value => {
                value.assigned.sort((a, b) => {
                    const dateA = a.expiryDate ? new Date(a.expiryDate) : new Date(8640000000000000)
                    const dateB = b.expiryDate ? new Date(b.expiryDate) : new Date(8640000000000000)
                    return dateA.getTime() - dateB.getTime();
                });
            })
        }
    }

    close(){
        this.dialogRef.close();
    }
}
