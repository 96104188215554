import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class SuperAdminService {

    protected basePath = environment.apiPath;
    public defaultHeaders = new HttpHeaders();
    productGroupName = '';

    constructor(protected http: HttpClient) {
        this.defaultHeaders = this.defaultHeaders.append('Accept', 'application/json');
        this.defaultHeaders = this.defaultHeaders.append('Content-Type', 'application/json');
    }

    public companies(pageNumber: any, pageSize: any, sortBy: any, order: any, params: string): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/superadmin/company?pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&${params}`, { headers });
    }

    public createClient(body: any): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.post<any>(`${this.basePath}/superadmin/company`, body, { headers });
    }

    public copyClient(body: any): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.post<any>(`${this.basePath}/superadmin/copy-company`, body, { headers });
    }

    public updateClient(id: any, body: any): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.post<any>(`${this.basePath}/superadmin/company/${id}`, body, { headers });
    }

    public getCompany(id: any): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/superadmin/company/${id}`, { headers });
    }

    public getCompanyAgreements(id: number, pageNumber: any, pageSize: any, sortBy: any, order: any, params: string): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/superadmin/company/${id}/agreement?pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&${params}`, { headers });
    }

    public getCompanyPaths(id: number, pageNumber: any, pageSize: any, sortBy: any, order: any, params: string): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/superadmin/company/${id}/path?pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&${params}`, { headers });
    }

    public getCompanyPathsXLS(id: number, sortBy: any, order: any, params: string): string {
        return `/superadmin/company/${id}/path/xls?sortBy=${sortBy}&order=${order}&${params}`;
    }

    public getCompanyTrainings(id: number, pageNumber: any, pageSize: any, sortBy: any, order: any, params: string): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/superadmin/company/${id}/training?pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&${params}`, { headers });
    }

    public getCompanyTrainingsXLS(id: number, sortBy: any, order: any, params: string): string {
        return `/superadmin/company/${id}/training/xls?sortBy=${sortBy}&order=${order}&${params}`;
    }

    public getCompanyPools(type: string, id: number, pageNumber: any, pageSize: any, sortBy: any, order: any, params: string): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/superadmin/company/${id}/pools?type=${type}&pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&${params}`, { headers });
    }

    public getCompanyPoolsXLS(type: string, id: number, sortBy: any, order: any, params: string): string {
        return `/superadmin/company/${id}/pools/xls?type=${type}&sortBy=${sortBy}&order=${order}&${params}`;
    }

    public getCompanyArticles(id: number, pageNumber: any, pageSize: any, sortBy: any, order: any, params: string): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/superadmin/company/${id}/knowledge-base-entry?pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&${params}`, { headers });
    }

    public getCompanyArticleCategories(id: number): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/superadmin/company/${id}/knowledge-base-category`, { headers });
    }

    public getCompanyArticlesXLS(id: number, params: string): string {
        return `/superadmin/company/${id}/knowledge-base-entry/xls?${params}`;
    }

    public getCompanyFeedback(id: number, pageNumber: any, pageSize: any, sortBy: any, order: any, params: string): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/superadmin/company/${id}/feedback?pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&${params}`, { headers });
    }

    public getCompanyFeedbackXLS(id: number, params: string): string {
        return `/superadmin/company/${id}/feedback/xls?${params}`;
    }

    public getCompanyUsers(id: number, pageNumber: any, pageSize: any, sortBy: any, order: any, params: string): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/superadmin/company/${id}/user?pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&${params}`, { headers });
    }

    public downloadCompanyUsersXls(id: number, params: string, translations): string {
        return `/superadmin/company/${id}/user/xls?${params}`;
    }

    public getUsers(pageNumber: any, pageSize: any, sortBy: any, order: any, params: string): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/superadmin/user?pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&${params}`, { headers });
    }

    public getPaths(pageNumber: any, pageSize: any, sortBy: any, order: any, params: string): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/superadmin/path?pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&${params}`, { headers });
    }

    public getTrainings(pageNumber: any, pageSize: any, sortBy: any, order: any, params: string): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/superadmin/training?pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&${params}`, { headers });
    }

    public getPools(type: string, pageNumber: any, pageSize: any, sortBy: any, order: any, params: string): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/superadmin/pools?type=${type}&pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&${params}`, { headers });
    }

    public getCompanyCertificatesTemplates(id: number, pageNumber: any, pageSize: any, sortBy: any, order: any, params: string): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/company/${id}/certificate-template-list?pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&${params}`, { headers });
    }

    public getCompanyActivities(id: number, pageNumber: any, pageSize: any, sortBy: any, order: any, params: string): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/superadmin/company/${id}/user/activity?pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&${params}`, { headers });
    }

    public downloadCompanyActivitiesXls(id: number, params: string): string {
        return `/superadmin/company/${id}/user/activity/xls?${params}`;
    }

    public getCompanyProductGroups(id: any): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/superadmin/company/${id}/product-group`, { headers });
    }

    public getProductGroupPaths(id: any, pageNumber: any, pageSize: any, sortBy: any, order: any, params: string): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/superadmin/company/${id}/product-group/path?pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&${params}`, { headers });
    }

    public getProductGroupPathsXLS(id: any, sortBy: any, order: any, params: string): string {
        return `/superadmin/company/${id}/product-group/path/xls?sortBy=${sortBy}&order=${order}&${params}`
    }

    public getProductGroupFeedbackQuestionnaires(id: any, pageNumber: any, pageSize: any, sortBy: any, order: any, params: string): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/superadmin/company/${id}/product-group/feedback_questionnaire?pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&${params}`, { headers });
    }

    public getProductGroupFeedbackQuestionnairesXLS(id: any, sortBy: any, order: any, params: string): Observable<HttpResponse<Blob>> {
        // @ts-ignore
        // @ts-disable
        return this.http.get<HttpResponse<Blob>>(`${this.basePath}/superadmin/company/${id}/product-group/feedback_questionnaire/xls?sortBy=${sortBy}&order=${order}&${params}`, { observe: 'response', responseType: 'blob', headers: 'X-Downloadable:true' });
    }

    public getProductGroupTrainings(id: any, pageNumber: any, pageSize: any, sortBy: any, order: any, params: string): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/superadmin/company/${id}/product-group/training?pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&${params}`, { headers });
    }

    public getProductGroupTrainingsXLS(id: any, sortBy: any, order: any, params: string): string {
        return `/superadmin/company/${id}/product-group/training/xls?sortBy=${sortBy}&order=${order}&${params}`
    }

    public getProductGroupPools(id: any, pageNumber: any, pageSize: any, sortBy: any, order: any, params: string): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/superadmin/company/${id}/product-group/pool?pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&${params}`, { headers });
    }

    public getProductGroupPoolsXLS(id: any, sortBy: any, order: any, params: string): string {
        return `/superadmin/company/${id}/product-group/pool/xls?sortBy=${sortBy}&order=${order}&${params}`;
    }

    public getProductGroups(pageNumber: any, pageSize: any, sortBy: any, order: any, params: string): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/superadmin/product-group?pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&${params}`, { headers });
    }

    public getProductGroupItems(id: any, type: 'training' | 'path' | 'pool' | 'survey' | 'feedback_questionnaire', pageNumber: any, pageSize: any, sortBy: any, order: any, params: string): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/superadmin/product-group/${id}/${type}?pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&${params}`, { headers });
    }

    public saveProductGroup(id: any, body: any): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.post<any>(`${this.basePath}/superadmin/product-group/${id}`, body, { headers });
    }

    public createProductGroup(body: any): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.post<any>(`${this.basePath}/superadmin/product-group`, body, { headers });
    }

    public saveClientGroups(id: any, body: any): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.post<any>(`${this.basePath}/superadmin/company/${id}/product-group`, body, { headers });
    }

    public createLanguage(body: any): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.post<any>(`${this.basePath}/superadmin/language`, body, { headers });
    }

    public updateLanguage(id: any, body: any): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.post<any>(`${this.basePath}/superadmin/language/${id}`, body, { headers });
    }

    public createAgreement(idCompany: any, body: any): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.post<any>(`${this.basePath}/superadmin/company/${idCompany}/agreement`, body, { headers });
    }

    public removeProductGroup(id: any): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.delete<any>(`${this.basePath}/superadmin/product-group/${id}`, { headers });
    }

    public getAvatars(): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/superadmin/avatar`, { headers });
    }

    public removeAvatar(id: number): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.delete<any>(`${this.basePath}/superadmin/avatar/${id}`, { headers });
    }

    public uploadAvatar(body: any): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.post<any>(`${this.basePath}/superadmin/avatar`, body, { headers });
    }

    public loginAsClient(id: number): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/superadmin/company/${id}/admin-login`, { headers });
    }

    public clientsXls(sortBy: any, order: any, params: string): string {
        return `/superadmin/company/xls?sortBy=${sortBy}&order=${order}&${params}`;
    }

    public clientAgreementsXls(id: number, sortBy: any, order: any, params: string): string {
        return `/superadmin/company/${id}/agreement/xls?sortBy=${sortBy}&order=${order}&${params}`;
    }

    public archiveCompany(companyId: number): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.post<any>(`${this.basePath}/superadmin/company/archive/${companyId}`, {}, { headers });
    }

    public downloadUsersXls(params: string): string {
        return `/superadmin/user/xls?${params}`;
    }

    public createVersion(body: any): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.post<any>(`${this.basePath}/superadmin/version`, body, { headers });
    }

    public getGuides(sortBy: string, order: string, params: string): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/superadmin/guide?sortBy=${sortBy}&order=${order}&${params}`, { headers });
    }

    public createEditGuide(body: any): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.post<any>(`${this.basePath}/superadmin/guide`, body, { headers });
    }

    public deleteGuides(id: number): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.delete<any>(`${this.basePath}/superadmin/guide/${id}`, { headers });
    }

    public guideAccessLevels(idGuide: number): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/superadmin/guide/${idGuide}/access-levels-checklist`, { headers });
    }

    public assignAccessLevels(idGuide: number, body: any): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.post<any>(`${this.basePath}/superadmin/guide/${idGuide}/assign-access-levels`, body, { headers });
    }

    public checkLoginUrl(id: string, url: string): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.post<any>(`${this.basePath}/superadmin/company/${id}/check-login-url`, { url }, { headers });
    }

    public getLoginPage(id: string): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/superadmin/company/${id}/login-page`, { headers });
    }

    public createEditLoginPage(id: string, body): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.post<any>(`${this.basePath}/superadmin/company/${id}/login-page`, body, { headers });
    }

    public downloadPathAdmin(params: string): string {
        return `/path/editor-list/xls?${params}`;
    }

    public downloadTrainingAdmin(params: string): string {
        return `/training/editor-list/xls?${params}`;
    }

    public getGlobalLoginPageConfiguration() {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/configuration/login-page`, { headers });
    }

    public updateGlobalLoginPage(body) {
        const headers = this.defaultHeaders;
        return this.http.post<any>(`${this.basePath}/configuration/login-page/update`, body, { headers });
    }

    public downloadPoolAdmin(params: string): string {
        return `/admin/pools/xls?${params}`;
    }

    public getAllowedIps() {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/configuration/allowed-ips`, { headers });
    }

    public saveAllowedIps(body): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.post<any>(`${this.basePath}/configuration/allowed-ips`, body, { headers });
    }

    public getCronNotificationEmails() {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/configuration/cron-notification-emails`, { headers });
    }

    public assignCronNotificationEmails(body): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.post<any>(`${this.basePath}/configuration/cron-notification-emails`, body, { headers });
    }

    public getCompanySubscriptions(id: number, pageNumber: any, pageSize: any, sortBy: any, order: any, params: string): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/superadmin/company/${id}/subscriptions?pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&${params}`, { headers });
    }

    public editCompanySubscription(id: number, body) {
        const headers = this.defaultHeaders;
        return this.http.post<any>(`${this.basePath}/superadmin/company/${id}/edit-subscription`, body, { headers });
    }

    public getLanguages(params?: string, sortBy?: any, order?: any): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/superadmin/languages?sortBy=${sortBy}&order=${order}&${params}`, { headers });
    }

    public editLanguageInterfaceState(id: number): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.post<any>(`${this.basePath}/superadmin/languages/interface/state/${id}`, {}, { headers });
    }

    public getCompanyInterfaceLanguages(id: number): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/superadmin/company/${id}/languages/interface`, { headers });
    }

    public getCompanyContentLanguages(id: number): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/superadmin/company/${id}/languages/content`, { headers });
    }

    public editCompanyLanguage(id: number, body: any): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.post<any>(`${this.basePath}/superadmin/company/${id}/languages/edit`, body, { headers });
    }

    public setAsDefaultCompanyLanguage(id: number, body: any): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.post<any>(`${this.basePath}/superadmin/company/${id}/languages/set/default`, body, { headers });
    }

    public getGraphicBase(type: string = ''): Observable<any> {
        const headers = this.defaultHeaders;
        type = !!type ? `?type=${type}` : ''
        return this.http.get<any>(`${this.basePath}/superadmin/cover${type}`, { headers });
    }

    public setGraphicBase(body: any): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.post<any>(`${this.basePath}/superadmin/cover`, body, { headers });
    }

    public removeGraphicBase(id: number): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.delete<any>(`${this.basePath}/superadmin/cover/${id}`, { headers });
    }

    public downloadClientsStatsXls(): string {
        return `/superadmin/company/xls/stats`;
    }

    public getActivities(pageNumber: number, pageSize: number, sortBy: any, order: string, params: string): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/superadmin/activity?pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&${params}`, { headers });
    }

    public getActivitiesXls(params): string {
        return `/superadmin/activity/xls?${params}`
    }

    public getCronLogs(pageNumber: number, pageSize: number, sortBy: any, order: string, params: string): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/superadmin/cron-logs?pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&${params}`, { headers });
    }

    public getCronLogsXls(params): string {
        return `/superadmin/cron-logs/xls?${params}`;
    }

    public getOpenAiLogs(pageNumber: number, pageSize: number, sortBy: any, order: string, params: string): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/superadmin/openai-logs?pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&${params}`, { headers });
    }

    public getOpenAiLogsXls(params): string {
        return `/superadmin/openai-logs/xls?${params}`;
    }

    public getMaintenance(): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/superadmin/maintenance`, { headers });
    }

    public saveMaintenance(description: string): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.post<any>(`${this.basePath}/superadmin/maintenance`, { description }, { headers });
    }

    public saveMaintenanceVisibility(body: any): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.post<any>(`${this.basePath}/superadmin/maintenance/visibility`, { ...body }, { headers });
    }

    public getAccessSysAdmin(): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/access-level?isSystem=1`, { headers });
    }

    public accessSysAdminLevels(pageNumber: number, pageSize: number, sortBy: any, order: string, params: string): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/superadmin/access-role?isSystem=1&pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&${params}`, { headers });
    }

    public getSysAdminUserRole(idRole: number) {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/superadmin/system-user?roleId=${idRole}`, { headers });
    }

    public addSysAdminsToRole(usersIds: number[], roleId: number, expiryDate: string): Observable<any> {
        const headers = this.defaultHeaders;
        const body = {
            usersIds,
            roleId,
            expiryDate
        }
        return this.http.post<any>(`${this.basePath}/superadmin/system-user/role`, { ...body }, { headers });
    }

    public deleteSysAdminRole(roleId: number, usersIds: number[]): Observable<any> {
        const options = {
            headers: this.defaultHeaders,
            body: {
                usersIds,
                roleId
            }
        };
        return this.http.delete<any>(`${this.basePath}/superadmin/system-user/role`, options);
    }

    public anonymizeGlobalUser(userid: number): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.post<any>(`${this.basePath}/superadmin/user/anonymize/${userid}`, {}, { headers });
    }
}
