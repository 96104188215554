import { Component, EventEmitter, OnInit, Output, QueryList, ViewChildren, ViewChild } from '@angular/core';
import { Input } from '@angular/core';
import { debounceTime } from 'rxjs/operators';
import { Subject } from 'rxjs';
import * as moment from 'moment';
import { DaterangepickerDirective } from 'ngx-daterangepicker-material';
import { DatePipe } from '@angular/common';
import { IFilter } from '../../intefaces/interfaces';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { environment } from '../../../../environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { AppLocaleService } from '../../../core/services/locale.service';
import { RequestInterface } from '../../intefaces/pagination.interface';
import {MatMenuTrigger} from '@angular/material/menu';

@Component({
    selector: 'app-data-grid',
    templateUrl: './data-grid.component.html',
    styleUrls: ['./data-grid.component.scss']
})
export class DataGridComponent implements OnInit {
    @ViewChildren(DaterangepickerDirective) datePickers: QueryList<DaterangepickerDirective>;
    searchSubject: Subject<any> = new Subject();
    @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;
    @Input() request: RequestInterface = {
        sort: {
            field: 'id',
            direction: 'asc'
        },
        pagination: {
            pageNumber: 1,
            pageSize: 50,
            totalElementsCount: 0,
            totalPages: 0
        },
        waitingForData: false
    };

    @Input() noTabs: boolean = true;
    @Input() selection: boolean = true;
    @Input() radioSelect: boolean = false;
    @Input() toogle: boolean = false;
    @Input() columns: any[] = [];

    @Input() set refreshSelectedItemsArray(value: boolean) {
        this._refreshSelectedItemsArray = value;
        if (value) {
            this.selectedItemsArray = [];
        }
        this._selectedAll = this._items.length === this._items.filter(item => this.selectedItemsArray.find(sItem => JSON.stringify(item) === JSON.stringify(sItem))).length && this.selectedItemsArray.length > 0 && this._items.length > 0;
    }

    @Input() extraParams: any = {};

    @Input() set items(items: any[]) {
        this._items = items;
        if (this.selectedItemsArray.length === 0) {
            const selectedItems = this._items.filter(value => value.selected === true);
            this.selectedItemsArray = [...selectedItems]
        }
        setTimeout(() => {
            document.getElementsByClassName('ft-body')[0].scrollLeft = this.headerScrollAfterFilter;
            document.getElementsByClassName('ft-head')[0].scrollLeft = this.headerScrollAfterFilter;
        })

        this._selectedAll = this._items.length === this._items.filter(item => this.selectedItemsArray.find(sItem => JSON.stringify(item) === JSON.stringify(sItem))).length && this.selectedItemsArray.length > 0 && this._items.length > 0;
    }

    @Input() dragAndDrop: boolean = false;
    @Input() disabledRemovedClass: boolean = false;
    @Output() loadData = new EventEmitter();
    @Output() reorder = new EventEmitter();
    @Output() itemRadioSelected = new EventEmitter();
    @Output() itemCheckboxSelected = new EventEmitter();

    @Input() filtersUrl: string = '';

    _items: any[] = [];
    _selectedAll: boolean = false;
    _refreshSelectedItemsArray: boolean = false;
    headerScrollAfterFilter: number = 0;
    disabledSelectionCount: number = 0;

    public filesPath = environment.filesPath;
    defaultLocale: any = {
        daysOfWeek: [
            this._translate.instant('Common.ShortSunday'),
            this._translate.instant('Common.ShortMonday'),
            this._translate.instant('Common.ShortTuesday'),
            this._translate.instant('Common.ShortWednesday'),
            this._translate.instant('Common.ShortThursday'),
            this._translate.instant('Common.ShortFriday'),
            this._translate.instant('Common.ShortSaturday')
        ],
        monthNames: [
            this._translate.instant('Common.January'),
            this._translate.instant('Common.February'),
            this._translate.instant('Common.March'),
            this._translate.instant('Common.April'),
            this._translate.instant('Common.May'),
            this._translate.instant('Common.June'),
            this._translate.instant('Common.July'),
            this._translate.instant('Common.August'),
            this._translate.instant('Common.September'),
            this._translate.instant('Common.October'),
            this._translate.instant('Common.November'),
            this._translate.instant('Common.December')
        ]
    };

    selectedIndex = null;

    selectedItemsArray: any[] = [];
    dateRangeValues: any[] = [];

    constructor(private datePipe: DatePipe,
                private _localeService: AppLocaleService,
                private _translate: TranslateService) {
        _localeService.currentLocale().subscribe(q => {
            moment.locale(q);
        });
        const $body = $('#body');
        // if ($body.width() < 720) {
        //     document.body.classList.remove('nav-collapsed');
        // } else {
        //     document.body.classList.add('nav-collapsed');
        // }

        this.createDefaultLocal();

        this.searchSubject.pipe(
            debounceTime(1000)
        ).subscribe(() => {
            this.acceptFilters();
        });
    }

    ngOnInit(): void {
        //
    }

    createDefaultLocal() {
        this.defaultLocale = {
            ...this.defaultLocale,
            separator: ' - ',
            cancelLabel: this._translate.instant('Common.Cancel'),
            applyLabel: this._translate.instant('Common.Accept'),
            clearLabel: this._translate.instant('Common.Clear')
        }
    }

    acceptFilters(): void {
        this.headerScrollAfterFilter = document.getElementsByClassName('ft-head')[0].scrollLeft;
        this.request.pagination.pageNumber = 1;
        this.loadData.emit(this.buildFiltersUrl(this.columns));
    }

    search(value: string = '') {
        if (value.length >= 2) {
            this.searchSubject.next();
        } else if (value === '') {
            this.acceptFilters();
        }
    }

    dateRangeSearch(column) {
        this.dateRangeValues = [];
        column.value = null;
        this.acceptFilters();
    }

    setDateRangeSearch(column, event){
        if (event !== null && event.startDate) {
            this.dateRangeValues[column.index] = {
                startDate: event.startDate,
                endDate: event.endDate,
            };
        }else{
            column.value = null;
            this.dateRangeValues[column.index] = {
                startDate: null,
                endDate: null,
            };
        }
    }

    searchDate(value: string = '') {
        if (value.length === 10) {
            this.acceptFilters();
        } else if (value === '') {
            this.acceptFilters();
        }
    }

    changeNoDate(column, event){
        if(event.checked){
            if(this.dateRangeValues[column.index] && this.dateRangeValues[column.index]['startDate']){
                column.value = null;
            }
            this.dateRangeValues[column.index] = {
                toggle: true,
            };
        }else{
            this.dateRangeValues[column.index] = {
                toggle: false
            };
        }
    }

    applyChanges(column){
        if(this.dateRangeValues[column.index] && this.dateRangeValues[column.index]['toggle']){
            column.value = 'novalue';
            this.dateRangeValues[column.index] = {
                startDate: null,
                endDate: null
            };
            this.acceptFilters();
        }else if(this.dateRangeValues[column.index] && this.dateRangeValues[column.index]['startDate']){
            this.trigger.closeMenu();
            column.value = this.dateRangeValues[column.index]['startDate'].format('DD-MM-YYYY HH:mm:ss') + '-' + this.dateRangeValues[column.index]['endDate'].format('DD-MM-YYYY HH:mm:ss');
            this.acceptFilters();
        }else{
            this.dateRangeSearch(column)
        }
        this.trigger.closeMenu();

    }

    searchNumber(column, numbers: any) {
        column.valueRange = { ...numbers }

        if (numbers.from && !numbers.to) {
            column.value = 'Od ' + numbers.from;
            this.acceptFilters();
        } else if (!numbers.from && numbers.to) {
            column.value = 'Do ' + numbers.to;
            this.acceptFilters();
        } else if (numbers.from && numbers.to) {
            column.value = 'Od ' + numbers.from + ' Do ' + numbers.to;
            this.acceptFilters();
        }
    }

    changeSort(field: string): void {
        if (field === this.request.sort.field) {
            if (this.request.sort.direction === 'asc') {
                this.request.sort.direction = 'desc';
            } else {
                this.request.sort.direction = 'asc';
            }
        } else {
            this.request.sort.direction = 'asc';
            this.request.sort.field = field;
        }

        this.request.pagination.pageNumber = 1;
        this.headerScrollAfterFilter = document.getElementsByClassName('ft-head')[0].scrollLeft;
        this.loadData.emit(this.buildFiltersUrl(this.columns));
    }

    loadMore(): void {
        if (this.request.waitingForData === false && this._items.length < this.request.pagination.totalElementsCount) {
            this.request.pagination.pageNumber++;
            this.loadData.emit(this.buildFiltersUrl(this.columns));
        }
    }

    buildFiltersUrl(filters: IFilter[]) {
        let index = 0;
        let paramsURI = '';
        for (const filter of filters) {
            if (filter.type === 'TOGGLE') {
                if (filter.value === '0' || filter.value === '1' || filter.value === 0 || filter.value === 1) {
                    paramsURI = `${index > 0 ? '&' : ''}${filter.index}=${filter.value}`;
                }
                continue;
            }

            if (filter.value === filter.defaultValue) {
                continue;
            }

            if (!filter.value) {
                continue;
            }

            if (filter.type === 'ARRAY') {
                if (filter.value.length === 0) {
                    filter.value = '';
                    continue;
                }
            }

            if (index > 0) {
                paramsURI += `&`;
            }

            let value = filter.value;

            if (filter.type === 'DATE') {
                value = this.convertToApiDate(filter.value);
            }

            if (filter.type === 'NUMBER') {
                if (filter.valueRange.from) {
                    paramsURI += `${filter.index + '[from]'}=${filter.valueRange.from}&`;
                }
                if (filter.valueRange.to) {
                    paramsURI += `${filter.index + '[to]'}=${filter.valueRange.to}&`;
                }

                index++;
                continue;
            }

            if (filter.type === 'DATE_RANGE') {
                if(filter.value === 'novalue'){
                    paramsURI += `${filter.index + '[from]'}=novalue`;
                }else{
                    let i = 0;
                    if (filter.valueRange.startDate) {
                        paramsURI += `${filter.index + '[from]'}=${encodeURIComponent(moment(filter.valueRange.startDate).format('YYYY-MM-DDTHH:mm:ss'))}`;
                        i++;
                    }
                    if (filter.valueRange.endDate) {
                        if (i > 0) {
                            paramsURI += '&';
                        }
                        paramsURI += `${filter.index + '[to]'}=${encodeURIComponent(moment(filter.valueRange.endDate).format('YYYY-MM-DDTHH:mm:ss'))}`;
                    }
                }

            } else if (filter.type === 'ARRAY' && (filter.multiple === undefined || filter.multiple === true)) {
                let i = 0;
                for (const v of filter.value) {
                    if (i > 0) {
                        paramsURI += '&';
                    }
                    paramsURI += `${filter.index + '[]'}=${encodeURIComponent(String(v))}`;
                    i++;
                }
            } else {
                paramsURI += `${filter.index}=${encodeURIComponent(String(value))}`;
            }

            index++;
        }

        Object.entries(this.extraParams).forEach(
            ([key, value]) => {
                if (index > 0) {
                    paramsURI += `&`;
                }
                paramsURI += `${key}=${encodeURIComponent(String(value))}`;
                index++;
            }
        );

        return paramsURI;
    }

    convertToApiDate(date): string {
        return this.datePipe.transform(date, 'yyyy-MM-dd');
    }

    getLocale(column) {
        return Object.assign(this.defaultLocale, (column.locale || {}));
    }

    openDatePicker(id, column) {
        if(this.dateRangeValues[column.index] && !this.dateRangeValues[column.index]['toggle']){
            this.datePickers.forEach(d => {
                const t = {
                    elementRef: {
                        nativeElement: {
                            id: null
                        }
                    }
                };
                Object.assign(t, d);
                if (id === t.elementRef.nativeElement.id) {
                    d.open();
                    return;
                }
            });
        }
    }

    setDateRange(column){
        if(column.value === 'novalue'){
            this.dateRangeValues[column.index] = {
                toggle: true,
            };
        }
    }

    drop(event: CdkDragDrop<string[]>) {
        moveItemInArray(this._items, event.previousIndex, event.currentIndex);
        const ids = this._items.map(value => value.id);
        this.reorder.emit(ids);
    }

    setStringFromArray(input: any[]): string {
        return input.filter(value => value.selected).sort((a, b) => a.id - b.id).map(value => value.value).join(', ');
    }

    selectRadio(e: any, index: number) {
        this.selectedIndex = index;
        this.itemRadioSelected.emit(e);
    }

    selectedCheckbox(event: any, index: number) {
        this._items[index].selected = event.checked;

        if (event.checked) {
            this.selectedItemsArray.push(this._items[index])
        } else {
            this.selectedItemsArray.splice(this.selectedItemsArray.findIndex(val =>
                    JSON.stringify(val).split('selected')[0] === JSON.stringify(this._items[index]).split('selected')[0]),
                1)
        }

        this.selectedItemsArray = [...new Map(this.selectedItemsArray.map(v => [JSON.stringify({
            ...v,
            selected: event.checked
        }), { ...v, selected: event.checked }])).values()];
        this.itemCheckboxSelected.emit(this.selectedItemsArray);
        this._selectedAll = this._items.length === this._items.filter(item => this.selectedItemsArray.find(sItem => JSON.stringify(item) === JSON.stringify(sItem))).length && this.selectedItemsArray.length > 0 && this._items.length > 0;

    }

    selectAll(event: any) {
        this._items.forEach(v => {
            v.selected = event.checked;
            if (event.checked) {
                if(!v.disabledSelection || v.disabledSelection === false) {
                    this.selectedItemsArray.push(v)
                }else{
                    this.disabledSelectionCount++;
                }
            } else {
                this.disabledSelectionCount = 0;

                this.selectedItemsArray.splice(this.selectedItemsArray.findIndex(val =>
                        JSON.stringify(val).split('selected')[0] === JSON.stringify(v).split('selected')[0]),
                    1)
            }
        });

        this.selectedItemsArray = [...new Map(this.selectedItemsArray.map(v => [JSON.stringify({
            ...v,
            selected: event.checked
        }), { ...v, selected: event.checked }])).values()];
        this.itemCheckboxSelected.emit(this.selectedItemsArray);
        this._selectedAll = this._items.length === this._items.filter(item => this.selectedItemsArray.find(sItem => JSON.stringify(item) === JSON.stringify(sItem))).length && this.selectedItemsArray.length > 0 && this._items.length > 0;
    }

    get selectedItems(): string {
        return this._items.filter(value => value.selected).length.toString();
    }

    removeTooltipWhenDrag() {
        $(document).find('.below-top').css('display', 'none');
    }
}
