import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ApiService } from '../../../api/api.service';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../../environments/environment.prod';
import { capitalizeFirstLetter, humanizeMinutes } from '../../helpers';
import { HelperService } from '../../services/helper.service';
import { RequestInterface } from '../../intefaces/pagination.interface';

@Component({
    selector: 'app-choice-base-path-training-dialog',
    templateUrl: './choice-base-path-training-dialog.component.html',
    styleUrls: ['./choice-base-path-training-dialog.component.scss']
})
export class ChoiceBasePathTrainingDialogComponent implements OnInit {
    columns: any[] = [];
    items: any[] = [];
    filtersString: string = '';
    public request: RequestInterface = {
        sort: {
            field: 'name',
            direction: 'asc'
        },
        pagination: {
            pageNumber: 1,
            pageSize: 50,
            totalElementsCount: 0,
            totalPages: 0
        },
        waitingForData: false
    };
    gridExtraParams = {
        status: 'published'
    };
    public searchRequest: any = null;
    selectedItem = null;
    protected basePath = environment.apiPath;

    constructor(public dialogRef: MatDialogRef<ChoiceBasePathTrainingDialogComponent>,
                private _api: ApiService,
                private _translate: TranslateService,
                private _dialog: MatDialog,
                private _helper: HelperService,
                @Inject(MAT_DIALOG_DATA) public data: any) {
        this.createTable();
    }

    createTable() {
        this.columns = [
            {
                index: 'name',
                name: this.data.type === 'training' ? 'Common.TrainingName' : 'Common.PathName',
                searchable: true,
                sortable: true,
                value: '',
                defaultValue: '',
                visible: true,
                type: 'STRING',
                css: {
                    'padding-left': '20px',
                    'min-width': '241px',
                    'word-wrap': 'anywhere'
                },
                class: 'ft-auto'
            },
            {
                index: 'authorName',
                name: 'Common.Author',
                searchable: true,
                visible: true,
                sortable: true,
                value: '',
                defaultValue: '',
                type: 'STRING',
                css: {
                    'min-width': '182px',
                    'width': '182px',
                    'padding-right': '20px'
                },
                render: (v) => {
                    return `${v.authorName} ${v.authorSurname} `;
                }
            },
            {},
            {
                index: 'duration',
                name: 'Common.DurationM',
                searchable: true,
                sortable: true,
                visible: true,
                value: '',
                defaultValue: '',
                valueRange: null,
                type: 'NUMBER',
                hasMinutes: true,
                css: {
                    'min-width': '140px',
                    'width': '140px',
                    'padding-right': '20px'
                },
                render: (v) => {
                    return humanizeMinutes(v.duration);
                }
            }
        ];

        this.changeColumn();
    }

    ngOnInit(): void {
        this.data.type === 'training' ? this.getTrainings(this.filtersString) : this.getPaths(this.filtersString);
    }

    changeColumn() {
        if (this.data.type === 'training') {
            this.columns[2] = {
                index: 'type',
                name: 'Common.Format',
                searchable: true,
                sortable: true,
                visible: true,
                value: '',
                defaultValue: '',
                type: 'ARRAY',
                options: [
                    { id: 'video', value: 'Common.Video' },
                    { id: 'document', value: 'Common.Document' },
                    { id: 'h5p', value: 'Common.H5P' },
                    { id: 'scorm', value: 'Common.Scorm' },
                    { id: 'page', value: 'Common.Page' },
                    { id: 'url', value: 'Common.Url' }
                ],
                css: {
                    'min-width': '138px',
                    'width': '138px',
                    'padding-right': '20px'
                },
                render: (v) => {
                    return this._translate.instant(`Common.${capitalizeFirstLetter(v.type)}`);
                }
            }
        } else {
            this.columns[2] = {
                index: 'totalElementsCount',
                name: 'Common.Elements',
                searchable: true,
                sortable: true,
                visible: true,
                value: '',
                defaultValue: '',
                valueRange: null,
                type: 'NUMBER',
                hasMinutes: false,
                css: {
                    'min-width': '138px',
                    'width': '138px',
                    'padding-right': '20px'
                }
            }
        }
    }

    getTrainings(filtersString = ''): void {
        const req = this.request;

        if (this.searchRequest) {
            this.searchRequest.unsubscribe()
        }

        this.filtersString = filtersString === '' ? `status=published` :
            filtersString.indexOf(`status=published`) !== -1 ?
                filtersString.replace(`status=published`, `status=published`) :
                filtersString + `&status=published`;

        this.request.waitingForData = true;
        this.searchRequest = this._api.User.getCreatorTrainings(
            req.sort.field,
            req.sort.direction,
            this.filtersString
        ).subscribe(res => {
                this.items = req.pagination.pageNumber === 1 ? res.elements : this.items.concat(res.elements);
                this.request.pagination.totalElementsCount = res.totalElementsCount;
                this.request.pagination.totalPages = res.totalPages;
                this.request.waitingForData = false;
            }
        )
    }

    getPaths(filtersString = ''): void {
        const req = this.request;

        if (this.searchRequest) {
            this.searchRequest.unsubscribe()
        }

        this.filtersString = filtersString;

        this.request.waitingForData = true;
        this.searchRequest = this._api.User.getCreatorPaths(
            req.sort.field,
            req.sort.direction,
            this.filtersString
        ).subscribe(res => {
                this.items = req.pagination.pageNumber === 1 ? res.elements : this.items.concat(res.elements);
                this.request.pagination.totalElementsCount = res.totalElementsCount;
                this.request.pagination.totalPages = res.totalPages;
                this.request.waitingForData = false;
            }
        )
    }

    choiceData() {
        if (!this.selectedItem) {
            return
        }

        if (this.data.type === 'training') {
            this._api.Library.getTraining(this.selectedItem.id).subscribe(value => {
                value.isTraining = true;
                this._api.Library.baseSubject.next({
                    ...value,
                    name: this._helper.stripFileName(`${this._translate.instant('Common.CopyName')}${value.name}`, 60),
                    trainingId: null,
                    id: null,
                    status: 'draft',
                    cover: value.cover ? this.basePath + value.cover : null,
                    isUsed: 0
                });
                this.dialogRef.close();
            })
        } else {
            this._api.Library.getPath(this.selectedItem.id).subscribe(value => {
                value.isPath = true;
                this._api.Library.baseSubject.next({
                    ...value,
                    pathId: null,
                    id: null,
                    status: 'draft',
                    name: this._helper.stripFileName(`${this._translate.instant('Common.CopyName')}${value.name}`, 60),
                    cover: value.cover ? this.basePath + value.cover : null,
                    isUsed: 0
                });
                this.dialogRef.close();
            })
        }
    }

    selectItem(item: any) {
        this.selectedItem = item;
    }

    closeDialog() {
        this.dialogRef.close();
    }
}
